<template>
  <div class="flex justify-center">
    <!-- Main container -->
    <div class="w-full max-w-7xl mx-4 mt-8">
      <!-- Course Card -->
      <div class="p-8 mb-8">
        <h1 class="text-4xl mb-6 font-semibold text-gray-800">Content Unit</h1>
        <!-- Input box for unit title -->
        <input 
          v-model="localUnitTitle" 
          type="text" 
          placeholder="Add a title for this unit" 
          class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2 mb-4"
          @input="emitData"
        />

        <!-- Large content box -->
        <textarea 
          v-model="localUnitContent" 
          placeholder="Enter unit content..." 
          class="w-full h-48 border-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2 mb-4"
          @input="emitData"
        ></textarea>

        <!-- Section title: Complete unit -->
        <div class="flex items-center justify-between mb-4">
          <h3 class="text-lg font-semibold">Complete unit</h3>
          <!-- Dropdown for completion options -->
          <select 
            v-model="localCompletionOption" 
            class="border border-gray-300 focus:outline-none px-4 py-2"
            @change="emitData"
          >
            <option value="">Select completion option</option>
            <option value="time">After a period of time</option>
            <option value="question">With a question</option>
          </select>
        </div>

        <!-- Conditional rendering based on completion option -->
        <div v-if="localCompletionOption === 'time'" class="mb-4">
          <!-- Input box for time limit -->
          <input 
            v-model="localTimeLimit" 
            type="number" 
            placeholder="Seconds" 
            class="w-32 border border-gray-300 focus:outline-none px-4 py-2 mr-2"
            @input="emitData"
          />
        </div>

        <div v-else-if="localCompletionOption === 'question'" class="mb-4">

          <TestButton @add-question="openQuestionDialog" />

          <div v-for="(question, index) in localQuestions" :key="index" class="w-full bg-white shadow-md rounded-lg p-4 mb-4">
            <div class="flex justify-between items-center">
              <div>
                <h3 class="text-lg font-semibold">Question {{ index + 1 }}: {{ question.type }}</h3>
                <p>{{ question.question }}</p>
              </div>
              <div class="flex items-center space-x-2">
                <button @click="toggleQuestionDetails(index)">
                  <svg v-if="!question.showDetails" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 15l-7-7-7 7" />
                  </svg>
                </button>
                <button @click="deleteQuestion(index)">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500 hover:text-red-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
  
            <!-- Question Details -->
            <div v-if="question.showDetails" class="mt-4">
              <div v-if="question.options" class="mb-2">
                <h4 class="text-md font-semibold">Options:</h4>
                <ul class="list-disc list-inside">
                  <li v-for="(option, idx) in question.options" :key="idx">
                    {{ option.text }} <span v-if="option.checked">(Correct)</span>
                  </li>
                </ul>
              </div>
              <div v-if="question.pairs" class="mb-2">
                <h4 class="text-md font-semibold">Pairs:</h4>
                <ul class="list-disc list-inside">
                  <li v-for="(pair, idx) in question.pairs" :key="idx">
                    {{ pair.left }} = {{ pair.right }}
                  </li>
                </ul>
              </div>
              <div v-if="question.fillTheGaps" class="mb-2">
                <h4 class="text-md font-semibold">Fill the Gaps:</h4>
                <p>{{ question.fillTheGaps }}</p>
              </div>
              <div v-if="question.rules" class="mb-2">
                <h4 class="text-md font-semibold">Rules:</h4>
                <ul class="list-disc list-inside">
                  <li v-for="(rule, idx) in question.rules" :key="idx">
                    When it {{ rule.condition }} the word "{{ rule.word }}", add {{ rule.points }} points.
                  </li>
                </ul>
                <p>Required Points: {{ question.requiredPoints }}</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <TestDialog v-if="isDialogOpen" :isOpen="isDialogOpen" :type="questionType" @close="isDialogOpen = false" @save-question="saveQuestion" />

</template>

<script>
import TestButton from '@/components/AddCourse/TestButton.vue';
import TestDialog from '@/components/AddCourse/TestDialog.vue';

export default {
  data() {
    return {
      localUnitTitle: '',
      localUnitContent: '',
      localCompletionOption: '',
      localTimeLimit: null,
      localQuestions: [], // To store all the questions
      isDialogOpen: false,
    };
  },
  watch: {
    unitData: {
      handler(newData) {
        this.localUnitTitle = newData.title || '';
        this.localUnitContent = newData.content || '';
        this.localCompletionOption = newData.completionOption || '';
        this.localTimeLimit = newData.timeLimit || null;
        this.localQuestions = newData.questions || [];
      },
      immediate: true,
      deep: true,
    },
  },
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    unit_counter: {
      type: Number,
      required: true,
    },
  },
  components: {
    TestButton,
    TestDialog,
  },
  methods: {
    openQuestionDialog(type) {
      this.questionType = type;
      this.isDialogOpen = true;
    },
    saveQuestion(questionData) {
      this.localQuestions.push({ ...questionData, showDetails: false }); // Save the new question to the array
      this.emitData(); // Emit data after adding a question
    },
    deleteQuestion(index) {
      this.localQuestions.splice(index, 1); // Delete question
      this.emitData(); // Emit data after deleting a question
    },
    toggleQuestionDetails(index) {
      this.localQuestions[index].showDetails = !this.localQuestions[index].showDetails; // Toggle question details
    },
    emitData() {
      this.$emit('data-updated', {
        unit_order: this.unit_counter,
        title: this.localUnitTitle,
        content: this.localUnitContent,
        completionOption: this.localCompletionOption,
        timeLimit: this.localTimeLimit,
        questions: this.localQuestions,
      });
    }
  }
};
</script>
