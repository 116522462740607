<template>
  <div class="flex justify-center">
    <div class="w-full max-w-7xl mx-4 mt-8">
      <div class="p-8 mb-8">
        <h1 class="text-4xl mb-6 font-semibold text-gray-800">Test Unit</h1>
        
        <!-- Button to add new questions -->
        <TestButton @add-question="openQuestionDialog" />

        <!-- Display questions -->
        <div v-for="(question, index) in questions" :key="index" class="w-full bg-white shadow-md rounded-lg p-4 mb-4">
          <div class="flex justify-between items-center">
            <div>
              <h3 class="text-lg font-semibold">Question {{ index + 1 }}: {{ question.type }}</h3>
              <p>{{ question.question }}</p>
            </div>
            <div class="flex items-center space-x-2">
              <button @click="toggleQuestionDetails(index)">
                <svg v-if="!question.showDetails" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 15l-7-7-7 7" />
                </svg>
              </button>
              <button @click="deleteQuestion(index)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500 hover:text-red-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>

          <!-- Question Details -->
          <div v-if="question.showDetails" class="mt-4">
            <div v-if="question.options" class="mb-2">
              <h4 class="text-md font-semibold">Options:</h4>
              <ul class="list-disc list-inside">
                <li v-for="(option, idx) in question.options" :key="idx">
                  {{ option.text }} <span v-if="option.checked">(Correct)</span>
                </li>
              </ul>
            </div>
            <div v-if="question.pairs" class="mb-2">
              <h4 class="text-md font-semibold">Pairs:</h4>
              <ul class="list-disc list-inside">
                <li v-for="(pair, idx) in question.pairs" :key="idx">
                  {{ pair.left }} = {{ pair.right }}
                </li>
              </ul>
            </div>
            <div v-if="question.fillTheGaps" class="mb-2">
              <h4 class="text-md font-semibold">Fill the Gaps:</h4>
              <p>{{ question.fillTheGaps }}</p>
            </div>
            <div v-if="question.rules" class="mb-2">
              <h4 class="text-md font-semibold">Rules:</h4>
              <ul class="list-disc list-inside">
                <li v-for="(rule, idx) in question.rules" :key="idx">
                  When it {{ rule.condition }} the word "{{ rule.word }}", add {{ rule.points }} points.
                </li>
              </ul>
              <p>Required Points: {{ question.requiredPoints }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Question Dialog -->
    <TestDialog v-if="isDialogOpen" :isOpen="isDialogOpen" :type="questionType" @close="isDialogOpen = false" @save-question="saveQuestion" />
  </div>
</template>

<script>
import TestButton from '@/components/AddCourse/TestButton.vue';
import TestDialog from '@/components/AddCourse/TestDialog.vue';

export default {
  data() {
    return {
      questions: [], // To store all the questions
      isDialogOpen: false,
      questionType: '',
    };
  },
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    unit_order: {
      type: Number,
      required: true,
    },
  },
  watch: {
    unitData: {
      handler(newData) {
        if (newData && newData.questions) {
          this.questions = newData.questions;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    TestButton,
    TestDialog,
  },
  methods: {
    openQuestionDialog(type) {
      this.questionType = type;
      this.isDialogOpen = true;
    },
    saveQuestion(questionData) {
      this.questions.push({ ...questionData, showDetails: false }); // Save the new question to the array
      this.emitData(); // Emit the data-updated event to the parent
    },
    deleteQuestion(index) {
      this.questions.splice(index, 1); // Delete question
      this.emitData(); // Emit the data-updated event to the parent
    },
    toggleQuestionDetails(index) {
      this.questions[index].showDetails = !this.questions[index].showDetails; // Toggle question details
    },
    emitData() {
      const testData = {
        unit_order: this.unit_order,
        questions: this.questions,
      };
      this.$emit('data-updated', testData);
    },
  },
};
</script>
