<template>
  <div class="flex flex-col space-y-4">
    <!-- Full-width input box for the title -->
    <input 
      v-model="unitTitle"
      type="text" 
      placeholder="Enter unit title here" 
      class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2"
      @input="emitData"
    />

    <div v-if="!uploadedDoc && !isFileUploadSelected" class="flex space-x-4">
      <!-- Container for "Upload a document file" option -->
      <div 
        @click="selectFileUpload" 
        class="flex-1 flex items-center justify-center h-64 bg-gray-200 cursor-pointer"
      >
        <div class="text-center">
          <!-- Icon for uploading a document file -->
          <svg xmlns="http://www.w3.org/2000/svg" class="w-20 h-20 text-gray-600 mb-4 mx-auto" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v9m-5 0H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2M8 9l4-5 4 5m1 8h.01"/>
          </svg>
          <p class="mx-auto">Upload a document file</p>
        </div>
      </div>
    </div>

    <!-- Document upload input -->
    <div v-if="isFileUploadSelected && !uploadedDoc" class="mt-4 relative">
      <input 
        type="file" 
        @change="handleDocUpload" 
        accept=".pdf,.doc,.docx,.txt,.xls,.xlsx,.ppt,.pptx,.odt,.ods,.odp" 
        class="w-full border-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2"
      />
      <button 
        @click="resetFileUploadSelection" 
        class="absolute top-0 right-0 mt-3 mr-3 text-gray-500 hover:text-gray-700"
      >
        <svg class="w-5 h-5 text-gray-700 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6"/>
        </svg>
      </button>
    </div>

    <!-- Display the uploaded document -->
    <div v-if="uploadedDoc" class="relative">
      <button 
        @click="removeDoc" 
        class="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-bl z-10"
      >
        Remove
      </button>
      <div class="bg-white p-4 shadow-md rounded">
        <p>Uploaded Document: <a :href="uploadedDoc" target="_blank">{{ docFile?.name || "View Document" }}</a></p>
      </div>
    </div>

    <!-- Large content box -->
    <textarea 
      v-model="unitContent" 
      placeholder="Add content" 
      class="w-full h-64 border-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2 mt-4"
      @input="emitData"
    ></textarea>

    <!-- Section title: Complete unit -->
    <div class="flex items-center justify-between mb-4">
      <h3 class="text-lg font-semibold">Complete unit</h3>
      <!-- Dropdown for completion options -->
      <select 
        v-model="completionOption" 
        class="border border-gray-300 focus:outline-none px-4 py-2"
        @change="emitData"
      >
        <option value="">Select completion option</option>
        <option value="time">After a period of time</option>
        <option value="question">With a question</option>
      </select>
    </div>

    <!-- Conditional rendering based on completion option -->
    <div v-if="completionOption === 'time'" class="mb-4">
      <!-- Input box for time limit -->
      <input 
        v-model="timeLimit" 
        type="number" 
        placeholder="Seconds" 
        class="w-32 border border-gray-300 focus:outline-none px-4 py-2 mr-2"
        @input="emitData"
      />
    </div>

    <div v-else-if="completionOption === 'question'" class="mb-4">
      <TestButton @add-question="openQuestionDialog" />

      <div v-for="(question, index) in questions" :key="index" class="w-full bg-white shadow-md rounded-lg p-4 mb-4">
        <div class="flex justify-between items-center">
          <div>
            <h3 class="text-lg font-semibold">Question {{ index + 1 }}: {{ question.type }}</h3>
            <p>{{ question.question }}</p>
          </div>
          <div class="flex items-center space-x-2">
            <button @click="toggleQuestionDetails(index)">
              <svg v-if="!question.showDetails" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 15l-7-7-7 7" />
              </svg>
            </button>
            <button @click="deleteQuestion(index)">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500 hover:text-red-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>

        <!-- Question Details -->
        <div v-if="question.showDetails" class="mt-4">
          <div v-if="question.options" class="mb-2">
            <h4 class="text-md font-semibold">Options:</h4>
            <ul class="list-disc list-inside">
              <li v-for="(option, idx) in question.options" :key="idx">
                {{ option.text }} <span v-if="option.checked">(Correct)</span>
              </li>
            </ul>
          </div>
          <div v-if="question.pairs" class="mb-2">
            <h4 class="text-md font-semibold">Pairs:</h4>
            <ul class="list-disc list-inside">
              <li v-for="(pair, idx) in question.pairs" :key="idx">
                {{ pair.left }} = {{ pair.right }}
              </li>
            </ul>
          </div>
          <div v-if="question.fillTheGaps" class="mb-2">
            <h4 class="text-md font-semibold">Fill the Gaps:</h4>
            <p>{{ question.fillTheGaps }}</p>
          </div>
          <div v-if="question.rules" class="mb-2">
            <h4 class="text-md font-semibold">Rules:</h4>
            <ul class="list-disc list-inside">
              <li v-for="(rule, idx) in question.rules" :key="idx">
                When it {{ rule.condition }} the word "{{ rule.word }}", add {{ rule.points }} points.
              </li>
            </ul>
            <p>Required Points: {{ question.requiredPoints }}</p>
          </div>
        </div>
      </div>
    </div>   

    <TestDialog 
      v-if="isDialogOpen" 
      :isOpen="isDialogOpen" 
      :type="questionType" 
      @close="isDialogOpen = false" 
      @save-question="saveQuestion" 
    />
  </div>
</template>

<script>
import TestButton from '@/components/AddCourse/TestButton.vue';
import TestDialog from '@/components/AddCourse/TestDialog.vue';

export default {
  data() {
    return {
      unitTitle: '', 
      isFileUploadSelected: false,
      uploadedDoc: '', 
      docFile: null, // Store the actual document file
      unitContent: '', 
      completionOption: '', 
      timeLimit: null, 
      questions: [], 
      isDialogOpen: false, 
    };
  },
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    unit_order: {
      type: Number,
      required: true,
    },
  },
  components: {
    TestButton,
    TestDialog,
  },
  watch: {
    unitData: {
      handler(newData) {
        if (newData) {
          this.unitTitle = newData.title || '';
          this.unitContent = newData.content || '';
          this.uploadedDoc = newData.doc_path || '';
          this.completionOption = newData.completion_option || '';
          this.timeLimit = newData.time_limit || null;
          this.questions = newData.questions || [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    openQuestionDialog(type) {
      this.questionType = type;
      this.isDialogOpen = true;
    },
    saveQuestion(questionData) {
      this.questions.push({ ...questionData, showDetails: false });
      this.emitData();
    },
    deleteQuestion(index) {
      this.questions.splice(index, 1);
      this.emitData();
    },
    toggleQuestionDetails(index) {
      this.questions[index].showDetails = !this.questions[index].showDetails;
    },
    selectFileUpload() {
      this.isFileUploadSelected = true;
    },
    handleDocUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.docFile = file; // Save the file reference
        this.uploadedDoc = URL.createObjectURL(file);
        this.emitData(); // Emit data including the document file
      }
    },
    removeDoc() {
      this.uploadedDoc = '';
      this.docFile = null; // Clear the file reference
      this.questions = [];
      this.isFileUploadSelected = false;
      this.emitData();
    },
    resetFileUploadSelection() {
      this.isFileUploadSelected = false;
      this.uploadedDoc = '';
      this.emitData();
    },
    emitData() {
      const docData = {
        title: this.unitTitle,
        content: this.unitContent,
        unit_order: this.unit_order,
        doc_path: this.uploadedDoc, // Document file path (url)
        file: this.docFile, // Include the actual file
        completion_option: this.completionOption,
        time_limit: this.timeLimit,
        questions: this.questions, // Add the questions array
      };
      this.$emit('data-updated', docData);
    },
  }
};
</script>
