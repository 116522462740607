<template>
  <header>
    <nav class="bg-white border-gray-200 shadow-md dark:bg-gray-800 overflow-hidden  z-50 absolute w-full">
      <div class="flex items-center justify-center lg:justify-start lg:w-auto lg:order-1 border-b-2 border-gray-300 dark:border-gray-700 py-5" id="mobile-menu-2">
        <a href="https://flowbite.com" class="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="234.284" height="48.459" viewBox="0 0 234.284 48.459">
              <g id="Group_482" data-name="Group 482" transform="translate(-55 139)">
                <path id="Path_1753" data-name="Path 1753" d="M491.9,1.256A4.38,4.38,0,0,0,488.635,0H343.71V4.295h5.556a1.815,1.815,0,0,1,1.3.467,2.047,2.047,0,0,1,.55,1.533V20.231a3.434,3.434,0,0,0,.923,2.461,3.117,3.117,0,0,0,2.412.97h1.311V24.79h-1.311a3.119,3.119,0,0,0-2.412.973,3.438,3.438,0,0,0-.921,2.461V42.16a2.043,2.043,0,0,1-.55,1.533,1.815,1.815,0,0,1-1.3.467h-5.556v4.295H488.635a4.308,4.308,0,0,0,4.52-4.52V4.52A4.371,4.371,0,0,0,491.9,1.256" transform="translate(-203.87 -139)" fill="#f3374b"></path>
                <path id="Path_1771" data-name="Path 1771" d="M909.31,49.96h.807v2.108h.407V49.96h.807v-.37H909.31Z" transform="translate(-629.86 -176.349)" fill="#fff"></path>
                <path id="Path_1772" data-name="Path 1772" d="M921.09,49.59l-.938,1.543-.938-1.543h-.284v2.478h.407V50.481l.674,1.106h.284l.671-1.106v1.587h.407V49.59Z" transform="translate(-637.105 -176.349)" fill="#fff"></path>
                <path id="Path_1773" data-name="Path 1773" d="M74.067,74.47a8.467,8.467,0,0,1-6-2.066,7.493,7.493,0,0,1-2.224-5.778V63.954h4.621v2.671a3.518,3.518,0,0,0,.911,2.574,3.416,3.416,0,0,0,2.557.928,3.138,3.138,0,0,0,2.4-.911,3.632,3.632,0,0,0,.857-2.592V53.671h-4.2v-4.2H84.607v4.2h-2.8V66.625a7.1,7.1,0,0,1-7.738,7.844" transform="translate(5.412 -176.259)" fill="#f3374b"></path>
                <path id="Path_1774" data-name="Path 1774" d="M150.008,64.325h6.583l-2.977-11.939h-.629Zm-7.178,9.664,6.442-24.508h8.054l6.442,24.508h-4.761l-1.33-5.393h-8.753l-1.33,5.393H142.83Z" transform="translate(-52.574 -176.267)" fill="#f3374b"></path>
                <path id="Path_1775" data-name="Path 1775" d="M237.41,73.981V49.47h8.58l4.236,21.359h.629l4.236-21.359h8.58V73.981h-4.482V52.866h-.629l-4.2,21.114h-7.635l-4.2-21.114h-.629V73.981Z" transform="translate(-123.809 -176.259)" fill="#f3374b"></path>
                <path id="Path_1776" data-name="Path 1776" d="M7.358,24.792V23.664H8.669a3.119,3.119,0,0,0,2.412-.973A3.438,3.438,0,0,0,12,20.231V6.3a1.759,1.759,0,0,1,1.854-2h5.556V0H11.535A4.3,4.3,0,0,0,7.018,4.52V19.833a1.734,1.734,0,0,1-1.854,1.952H0v4.887H5.161a1.734,1.734,0,0,1,1.854,1.952V43.939a4.3,4.3,0,0,0,4.517,4.52h7.879V44.164H13.855a1.758,1.758,0,0,1-1.854-2V28.226a3.434,3.434,0,0,0-.923-2.461,3.117,3.117,0,0,0-2.412-.97H7.356Z" transform="translate(55 -139)" fill="#f3374b"></path>
                <path id="Path_1777" data-name="Path 1777" d="M422.7,73.106a10.021,10.021,0,0,1-7.213-2.537q-2.662-2.536-2.661-7.264V57.422q0-4.728,2.661-7.264A10.025,10.025,0,0,1,422.7,47.62c3.034,0,5.339.822,6.985,2.468a9.155,9.155,0,0,1,2.468,6.776v.21h-4.552v-.351a5.277,5.277,0,0,0-1.207-3.572,4.618,4.618,0,0,0-3.7-1.4,4.96,4.96,0,0,0-3.851,1.506,5.784,5.784,0,0,0-1.4,4.1v6.023a5.79,5.79,0,0,0,1.4,4.08,4.972,4.972,0,0,0,3.851,1.523,4.623,4.623,0,0,0,3.7-1.417,5.293,5.293,0,0,0,1.207-3.554V63.38h4.552v.491a9.155,9.155,0,0,1-2.468,6.776,9.445,9.445,0,0,1-6.985,2.468" transform="translate(-255.929 -174.866)" fill="#fff"></path>
                <path id="Path_1778" data-name="Path 1778" d="M514.263,68.974a5.291,5.291,0,0,0,4.026-1.506,5.586,5.586,0,0,0,1.437-4.026V57.279a5.586,5.586,0,0,0-1.437-4.026,6.112,6.112,0,0,0-8.035,0,5.547,5.547,0,0,0-1.454,4.026v6.163a5.543,5.543,0,0,0,1.454,4.026,5.306,5.306,0,0,0,4.009,1.506m0,4.132a10.381,10.381,0,0,1-7.353-2.537q-2.732-2.536-2.73-7.264V57.422q0-4.728,2.73-7.264a11.923,11.923,0,0,1,14.707,0q2.732,2.54,2.732,7.264V63.3q0,4.728-2.732,7.264a10.362,10.362,0,0,1-7.353,2.537" transform="translate(-324.73 -174.866)" fill="#fff"></path>
                <path id="Path_1779" data-name="Path 1779" d="M604.232,69.9h2.31a5.193,5.193,0,0,0,4.1-1.471,6.043,6.043,0,0,0,1.3-4.132v-4.9a6.035,6.035,0,0,0-1.3-4.149,5.231,5.231,0,0,0-4.1-1.454h-2.31V69.9Zm-7.842,4.2v-4.06h3.221V53.65H596.39V49.59h10.083q4.935,0,7.511,2.5t2.572,7.44v4.623q0,4.935-2.572,7.44t-7.511,2.5Z" transform="translate(-394.18 -176.349)" fill="#fff"></path>
                <rect id="Rectangle_314" data-name="Rectangle 314" width="4.621" height="24.508" transform="translate(226.228 -126.759)" fill="#fff"></rect>
                <path id="Path_1780" data-name="Path 1780" d="M731.14,74.1V49.59h8.787l4.868,21.356h.629V49.59h4.552V74.1h-8.787L736.321,52.74h-.629V74.1Z" transform="translate(-495.668 -176.349)" fill="#fff"></path>
                <path id="Path_1781" data-name="Path 1781" d="M832.934,73.1a9.253,9.253,0,0,1-4.569-1.138,8.274,8.274,0,0,1-3.256-3.327,10.915,10.915,0,0,1-1.209-5.339V57.412q0-4.728,2.661-7.264a10.025,10.025,0,0,1,7.213-2.537c3.034,0,5.339.8,6.985,2.4a8.635,8.635,0,0,1,2.468,6.494v.141h-4.552v-.281a5.011,5.011,0,0,0-.543-2.345,4,4,0,0,0-1.627-1.664,5.509,5.509,0,0,0-2.73-.612,5,5,0,0,0-3.851,1.506,5.783,5.783,0,0,0-1.4,4.1v6.023a5.928,5.928,0,0,0,1.4,4.115,5,5,0,0,0,3.922,1.558,4.615,4.615,0,0,0,3.675-1.33,4.94,4.94,0,0,0,1.155-3.362V64h-5.813V60.075H843.23V72.61h-4.273V70.265h-.629a5.164,5.164,0,0,1-.787,1.261,4.076,4.076,0,0,1-1.627,1.121,7.884,7.884,0,0,1-2.977.454" transform="translate(-565.532 -174.858)" fill="#fff"></path>
              </g>
            </svg>
        </a>
      </div>
    </nav>
  </header>
</template>
