<template>
    <div class="w-full bg-gray-200 rounded-full h-6 dark:bg-gray-700">
      <div
        class="bg-green-600 h-6 rounded-full"
        :style="{ width: progress + '%' }"
      ></div>
    </div>
    <div class="text-right text-sm text-gray-600 dark:text-gray-400 mt-1">
      {{ progress }}% Complete
    </div>
  </template>
  
  <script>
  export default {
    props: {
      progress: {
        type: Number,
        required: true,
        default: 0,
        validator(value) {
          return value >= 0 && value <= 100;
        },
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any additional styles here if needed */
  </style>
  