import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store'; // Import the store
import './assets/index.css';
import 'flowbite/dist/flowbite.js';
import 'flowbite/dist/flowbite.css';

// Correct import for SCORM_API
import { SCORM_API } from './scorm-api'; // Adjust path as needed

const app = createApp(App);

// Check if user is authenticated and retrieve token from local storage
const token = localStorage.getItem('token');

// If token exists, set default Authorization header for Axios
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// Make SCORM API globally available
window.API = SCORM_API;

app.use(router);
app.use(store);
app.mount('#app');
