<template>
  <div class="flex flex-col space-y-6">
    <!-- Full-width input box for the title -->
    <input 
      v-model="unitTitle"
      type="text" 
      placeholder="Enter unit title here" 
      class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2"
      @input="emitData"
    />

    <div v-if="!scormFileSelected && !uploadedScormPath" class="flex space-x-4">
      <!-- Container for "Upload a SCORM file" option -->
      <div 
        @click="selectFileUpload" 
        class="flex-1 flex items-center justify-center h-96 bg-gray-200 cursor-pointer rounded-lg shadow-lg hover:bg-gray-300 transition duration-300"
      >
        <div class="text-center">
          <!-- Icon for uploading a SCORM file -->
          <svg xmlns="http://www.w3.org/2000/svg" class="w-24 h-24 text-gray-600 mb-4 mx-auto" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v9m-5 0H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2M8 9l4-5 4 5m1 8h.01"/>
          </svg>
          <p class="mx-auto text-lg font-semibold">Upload a SCORM, xAPI, or cmi5 file</p>
        </div>
      </div>
    </div>

    <!-- SCORM upload input -->
    <div v-if="scormFileSelected && !scormFileName" class="mt-4 relative">
      <input 
        type="file" 
        @change="handleScormUpload" 
        accept=".zip,.xml" 
        class="w-full border-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2"
      />
      <button 
        @click="resetFileUploadSelection" 
        class="absolute top-0 right-0 mt-3 mr-3 text-gray-500 hover:text-gray-700"
      >
        <svg class="w-5 h-5 text-gray-700 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6"/>
        </svg>
      </button>
    </div>

    <!-- Display the uploaded SCORM file -->
    <div v-if="uploadedScormPath || scormFileName" class="relative flex flex-col items-center justify-center h-96 bg-blue-50 rounded-lg shadow-lg p-6">
      <div class="w-full text-center">
        <svg class="mx-auto mb-4 w-16 h-16 text-blue-600" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 2h12a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V4a2 2 0 012-2zm5 16.5v1a.5.5 0 001 0v-1a.5.5 0 00-1 0zm1.5 0a.5.5 0 10-1 0v1a.5.5 0 001 0v-1zM12 17c.556 0 1-.444 1-1h-2c0 .556.444 1 1 1zm6 2a1 1 0 01-1 1H7a1 1 0 01-1-1V4a1 1 0 011-1h10a1 1 0 011 1v15z"/></svg>
        <p class="text-xl font-semibold text-gray-800">{{ scormFileName || getFileNameFromPath(uploadedScormPath) }}</p>
        <p class="text-sm text-gray-600 mt-2">SCORM, xAPI, or cmi5 file</p>
      </div>
      <button 
        @click="removeScorm" 
        class="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition duration-300"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      unitTitle: '', 
      scormFileSelected: false,
      uploadedScormPath: '', 
      scormFile: null, 
      scormFileName: '',
    };
  },
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    unit_order: {
      type: Number,
      required: true,
    },
  },
  watch: {
    unitData: {
      handler(newData) {
        if (newData) {
          this.unitTitle = newData.title || '';
          this.uploadedScormPath = newData.scorm_path || '';
          this.scormFileName = newData.file ? newData.file.name : this.getFileNameFromPath(newData.scorm_path);
          this.scormFileSelected = !!newData.file; // Set to true if a file has been selected
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    selectFileUpload() {
      this.scormFileSelected = true;
    },
    handleScormUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.scormFile = file; // Save the file reference
        this.scormFileName = file.name;
        this.uploadedScormPath = ''; // Reset any existing path to ensure the new file is used
        this.emitData(); // Emit data including the scorm file
      }
    },
    removeScorm() {
      this.uploadedScormPath = '';
      this.scormFile = null; // Clear the file reference
      this.scormFileName = '';
      this.scormFileSelected = false;
      this.emitData();
    },
    resetFileUploadSelection() {
      this.scormFileSelected = false;
      this.uploadedScormPath = '';
      this.emitData();
    },
    emitData() {
      const scormData = {
        title: this.unitTitle,
        unit_order: this.unit_order,
        scorm_path: this.uploadedScormPath, // Path to SCORM file (if already uploaded)
        file: this.scormFile, // SCORM file reference for upload
      };
      this.$emit('data-updated', scormData);
    },
    getFileNameFromPath(path) {
      return path ? path.split('/').pop() : '';
    },
  }
};
</script>
