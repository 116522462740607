<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
      <TopNav></TopNav>
  
      <!-- Sidebar -->
      <SideMenu></SideMenu>
  
      <main class="p-4 md:ml-64 h-auto pt-20">
        <!-- grid 1 -->
        <div class="flex flex-row mb-4 mt-3">
          <!-- Main content area with variable grid layout -->
          <div class="basis-full lg:basis-10/12 mx-auto max-w-screen-3xl pr-5">
            <!-- Responsive grid layout -->
            <div class="mt-4 grid grid-cols-12 gap-4 md:grid-cols-12 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
              
              <!-- User Activity Analytics -->
              <div class="col-span-5 md:col-span-3 xl:col-span-12 2xl:col-span-5 rounded-sm border border-stroke bg-white px-5 pb-5 pt-7.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">
                <UserActivityAnalytics title="How am I doing?"></UserActivityAnalytics>
              </div>
  
              <!-- Recent Activities -->
              <div class="col-span-5 md:col-span-12 xl:col-span-12 2xl:col-span-3 rounded-sm border border-stroke bg-white p-7.5 pb-2 shadow-default dark:border-strokedark dark:bg-boxdark">
                <RecentActivities></RecentActivities>
              </div>
  
              <!-- Quick Links -->
              <div class="col-span-5 md:col-span-12 xl:col-span-12 2xl:col-span-4 rounded-sm border border-stroke bg-white p-7.5 pb-2 shadow-default dark:border-strokedark dark:bg-boxdark">
                <QuickLinks title="Quick Links"></QuickLinks>
              </div>
  
              <!-- Course Overview -->
              <div class="col-span-5 md:col-span-3 xl:col-span-12 2xl:col-span-5  rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
                <CourseOverview title="Recent Courses"></CourseOverview>
              </div>
  
              <!-- Recent Users -->
              <div class="col-span-5 md:col-span-3 xl:col-span-12 2xl:col-span-5  rounded-sm border border-stroke bg-white px-5 pb-5 pt-7.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">
                <BasicUserTable title="Recent Users"></BasicUserTable>
              </div>
            </div>
          </div>
          
          <!-- Sidebar on the right (account management section) -->
          <div class="hidden lg:block lg:basis-2/12 border-l pl-4 pt-5">
            <h3 class="font-semibold text-lg">Your Account</h3>
            <AccountManager title="Account Manager"></AccountManager>
            <CreditsSubscriptions title="Credits & Subscriptions"></CreditsSubscriptions>
            <TrainingURL title="Your 'Training suite URL'"></TrainingURL>
            <FAQList></FAQList>
          </div>
        </div>
      </main>
    </div>
  </template>
  
<script>
import TopNav from '@/components/headers/TopNav.vue';
import SideMenu from '@/components/SideMenu.vue';
import AccountManager from '@/components/dashboard/AccountManager.vue';
import BasicUserTable from '@/components/dashboard/BasicUserTable.vue';
import CreditsSubscriptions from '@/components/dashboard/CreditsSubscriptions.vue';
import FAQList from '@/components/dashboard/FAQList.vue';
import RecentActivities from '@/components/dashboard/RecentActivities.vue';
import TrainingURL from '@/components/dashboard/TrainingURL.vue';
import UserActivityAnalytics from '@/components/dashboard/UserActivityAnalytics.vue';
import QuickLinks from '@/components/dashboard/QuickLinks.vue';
import CourseOverview from '@/components/dashboard/CourseOverview.vue';

export default {
    components: {
        TopNav,
        SideMenu,

        AccountManager,
        BasicUserTable,
        CreditsSubscriptions,
        FAQList,
        RecentActivities,
        TrainingURL,
        UserActivityAnalytics,
        QuickLinks,
        CourseOverview
    },
};
</script>
