import axios from 'axios';

let storedValues = {}; // Temporary storage for synchronous SCORM API calls

export const SCORM_API = {
    scormContext: { scoUuid: null, scormId: null },

    setScormContext: function (scoUuid, scormId) {
        this.scormContext.scoUuid = scoUuid;
        this.scormContext.scormId = scormId;
        console.log('SCORM context set with scoUuid:', scoUuid, 'and scormId:', scormId);
    },

    LMSInitialize: function () {
        const { scoUuid, scormId } = this.scormContext;

        if (!scoUuid || !scormId) {
            console.error('LMSInitialize missing scoUuid or scormId:', scoUuid, scormId);
            return "false";
        }

        console.log('LMSInitialize called with scoUuid:', scoUuid, 'and scormId:', scormId);

        const payload = {
            scoUuid: scoUuid,
            scormId: scormId
        };

        // Asynchronously perform the initialization
        axios.post('/api/scorm/initialize', payload)
            .then(response => {
                console.log('Response from LMSInitialize:', response.data, typeof response.data);
                storedValues['initialized'] = response.data === 'true';
            })
            .catch(error => {
                console.error('LMSInitialize error:', error);
                storedValues['initialized'] = false;
            });

        return "true";
    },

    LMSFinish: function () {
        const { scoUuid, scormId } = this.scormContext;

        console.log('LMSFinish called with scoUuid:', scoUuid, 'and scormId:', scormId);

        const payload = {
            scoUuid: scoUuid,
            scormId: scormId
        };
        
        axios.post('/api/scorm/finish', payload)
            .then(response => {
                console.log('Response from LMSFinish:', response.data);
                storedValues['finished'] = true;
            })
            .catch(error => {
                console.error('LMSFinish error:', error);
                storedValues['finished'] = false;
            });

        return "true";
    },

    LMSGetValue: function (key) {
        const { scoUuid, scormId } = this.scormContext;

        console.log('LMSGetValue called with key:', key, 'scoUuid:', scoUuid, 'scormId:', scormId);

        if (storedValues[key]) {
            return storedValues[key];
        }

        axios.get(`/api/scorm/get-value?key=${key}&scoUuid=${scoUuid}&scormId=${scormId}`)
            .then(response => {
                console.log('Response from LMSGetValue:', response.data);
                storedValues[key] = response.data.value;
            })
            .catch(error => {
                console.error('LMSGetValue error:', error);
                storedValues[key] = ''; // Set a default or empty value if there's an error
            });

        return storedValues[key] || '';
    },

    LMSSetValue: function (key, value) {
        const { scoUuid, scormId } = this.scormContext;

        console.log('LMSSetValue called with key:', key, 'value:', value, 'scoUuid:', scoUuid, 'scormId:', scormId);

        storedValues[key] = value;

        axios.post('/api/scorm/set-value', { key: key, value: value, scoUuid: scoUuid, scormId: scormId })
            .then(response => {
                console.log('Response from LMSSetValue:', response.data);
            })
            .catch(error => {
                console.error('LMSSetValue error:', error);
            });

        return "true";
    },

    LMSCommit: function () {
        const { scoUuid, scormId } = this.scormContext;

        console.log('LMSCommit called with scoUuid:', scoUuid, 'and scormId:', scormId);

        const payload = {
            scoUuid: scoUuid,
            scormId: scormId
        };

        axios.post('/api/scorm/commit', payload)
            .then(response => {
                console.log('Response from LMSCommit:', response.data);
            })
            .catch(error => {
                console.error('LMSCommit error:', error);
            });

        return "true";
    },

    LMSGetLastError: function () {
        return "0"; // Return last error code
    },

    LMSGetErrorString: function () {
        return "No error"; // Return the corresponding error string
    },

    LMSGetDiagnostic: function () {
        return "No error diagnostic"; // Return the corresponding error diagnostic
    },
};
