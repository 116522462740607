<template>
    <CardHeader :title="title" font="lg" class="col-span-2"></CardHeader>

    <div class="relative overflow-x-auto mt-3 border-0">

        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Name
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Email
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Type
                    </th>
                    <th scope="col" class="px-6 py-3">
                        No. Courses
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Expired Courses
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row"
                        class="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <div class="ps-3">
                            <div class="text-md font-normal"><a href="#" class="text-blue-700">Neil Sims</a></div>
                        </div>
                    </th>
                    <td class="px-6 py-4">
                        <div class="font-normal text-gray-500">neil.sims@flowbite.com</div>
                    </td>
                    <td class="px-6 py-4">
                        Students
                    </td>
                    <td class="px-6 py-4">
                        <div class="flex items-center">
                            9
                        </div>
                    </td>
                    <td class="px-6 py-4">
                        0
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row"
                        class="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <div class="ps-3">
                            <div class="text-md font-normal"><a href="#" class="text-blue-700">Bonnie Green</a></div>
                        </div>
                    </th>
                    <td class="px-6 py-4">
                        <div class="font-normal text-gray-500">bonnie@flowbite.com</div>
                    </td>
                    <td class="px-6 py-4">
                        Students
                    </td>
                    <td class="px-6 py-4">
                        <div class="flex items-center">
                            9
                        </div>
                    </td>
                    <td class="px-6 py-4">
                        1
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row"
                        class="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <div class="ps-3">
                            <div class="text-md font-normal"><a href="#" class="text-blue-700">Jese Leos</a></div>
                        </div>
                    </th>
                    <td class="px-6 py-4">
                        <div class="font-normal text-gray-500">jese@flowbite.com</div>
                    </td>
                    <td class="px-6 py-4">
                        Instructer
                    </td>
                    <td class="px-6 py-4">
                        <div class="flex items-center">
                            25
                        </div>
                    </td>
                    <td class="px-6 py-4">
                        0
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row"
                        class="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <div class="ps-3">
                            <div class="text-md font-normal"><a href="#" class="text-blue-700">Thomas Lean</a></div>
                        </div>
                    </th>
                    <td class="px-6 py-4">
                        <div class="font-normal text-gray-500">thomes@flowbite.com</div>
                    </td>
                    <td class="px-6 py-4">
                        Students
                    </td>
                    <td class="px-6 py-4">
                        <div class="flex items-center">
                            5
                        </div>
                    </td>
                    <td class="px-6 py-4">
                        0
                    </td>
                </tr>
                <tr class="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row"
                        class="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <div class="ps-3">
                            <div class="text-md font-normal"><a href="#" class="text-blue-700">Leslie Livingston</a></div>
                        </div>
                    </th>
                    <td class="px-6 py-4">
                        <div class="font-normal text-gray-500">leslie@flowbite.com</div>
                    </td>
                    <td class="px-6 py-4">
                        Students
                    </td>
                    <td class="px-6 py-4">
                        <div class="flex items-center">
                            0
                        </div>
                    </td>
                    <td class="px-6 py-4">
                        5
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="self-center text-center mt-3">
    <a href="#" class="text-blue-700 flex items-center justify-center">
        <span class="inline-block">View all users</span>
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m10 16 4-4-4-4"/>
        </svg>
    </a>
</div>

    </div>
</template>
<script>
import CardHeader from '@/partials/CardHeader.vue';

export default {
    components: {
        CardHeader
    },
    props: {
        title: String
    }
};
</script>
