<template>
    <div class="test-slider">
      <!-- Loop over all tests and display them all on the page -->
      <TestSlide 
        v-for="(test, index) in tests" 
        :key="test.id" 
        :test="test" 
        :isActive="true"
        :savedAnswers="userAnswers[index] || {}" 
        @set-answer="setUserAnswer(index, $event)" 
      />
    </div>
  </template>
  
  <script>
  import TestSlide from '@/components/learner/units/TestSlide.vue';
  
  export default {
    props: {
      tests: {
        type: Array,
        required: true,
      },
      savedResponses: {
        type: Object,
        default: () => ({}), // Accept saved responses from parent
      },
    },
    data() {
      return {
        userAnswers: this.tests.map(() => null), // Initialize with null values for each test
      };
    },
    mounted() {
      // Restore saved answers if provided
      this.restoreAnswers(this.savedResponses);
    },
    methods: {
      // Directly assign the full answer object to userAnswers
      setUserAnswer(index, answer) {
        this.userAnswers[index] = answer; // Store the full answer object (not just isCorrect)
        this.$emit('test-answered', this.tests[index].id, answer); // Emit the full answer object
      },
  
      // Restore saved answers when the user navigates back to the unit
      restoreAnswers(savedResponses) {
        this.userAnswers = this.tests.map(test => savedResponses[test.id] || null); // Restore full answers for each test
      },
  
      // Return all answers for checking correctness
      getAllAnswers() {
        return this.userAnswers || [];
      },
    },
    components: {
      TestSlide,
    },
  };
  </script>
  