<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
    <TopNav />

    <!-- Sidebar -->
    <SideMenu />

    <main class="p-4 md:ml-64 h-auto pt-20">
      <section class="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
        <div class="mx-auto max-w-screen-xl px-4 lg:px-12">
          <!-- Start coding here -->
          <div class="dark:bg-gray-800 relative sm:rounded-lg overflow-hidden">
            <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
              <!-- Search Box -->
              <div class="w-full max-w-md">
                <form class="flex items-center">
                  <label for="simple-search" class="sr-only">Search</label>
                  <div class="relative w-full">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <input type="text" id="simple-search" v-model="searchQuery"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Search my courses" />
                  </div>
                </form>
              </div>

              <!-- Sort By Dropdown -->
              <div class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <select v-model="sortBy" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                  <option value="name">Sort by Name</option>
                  <option value="date">Sort by Date</option>
                  <option value="progress">Sort by Progress</option>
                </select>
              </div>
            </div>

            <!-- Loading Spinner -->
            <div v-if="loading" class="text-center py-10">

  <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
  </svg>
  <span class="sr-only">Loading...</span>
            </div>

            <!-- No Courses Message -->
            <div v-if="!loading && !filteredCourses.length" class="text-center text-gray-600 dark:text-gray-400 flex flex-col items-center justify-center h-80">
              <h2 class="text-3xl font-semibold mb-4">No Courses Assigned</h2>
              <p class="text-lg mb-2">It looks like you have not been assigned any courses yet.</p>
              <p class="text-md">Please contact your instructor or visit the <router-link class="text-blue-600 hover:underline" to="/learner/course-catalog">course catalog</router-link> to browse available courses.</p>
            </div>

            <!-- My Courses Grid -->
            <div v-else-if="!loading" class="overflow-x-auto p-4">
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                <!-- Each course card -->
                <div v-for="course in filteredCourses" :key="course.id" class="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
                  <img :src="getFullThumbnailUrl(course.thumbnail)" alt="Course Thumbnail" class="w-full h-48 object-cover" />
                  <div class="relative w-full bg-gray-200 h-4">
                    <div
                      class="bg-blue-600 h-4"
                      :style="{ width: course.progress + '%' }"
                    ></div>
                    <!-- Text inside the progress bar -->
                    <div 
                    class="absolute inset-0 flex items-center justify-center text-xs font-semibold"
                    :class="{ 'text-gray-800': course.progress < 20, 'text-white': course.progress >= 20 }">
                    {{ course.progress }}%
                  </div>
                  </div>
                  <div class="p-4">
                    <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">{{ course?.title || 'Untitled Course' }}</h3>                      
                    <p class="text-sm text-gray-500 dark:text-gray-400">Started: {{ formatDate(course.date_assigned) || 'N/A' }}</p>
                  </div>
                  <div class="p-4">
                    <router-link :to="{ name: 'ViewCourse', params: { id: course.id }, query: { from: 'myCourses' } }" class="w-full bg-primary-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-primary-700 transition-colors duration-200 text-center block">
                      View Course
                    </router-link>

                  </div>
                </div>
                <!-- End of course card -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import TopNav from '@/components/headers/TopNav.vue';
import SideMenu from '@/components/LearnerSideMenu.vue';

export default {
  components: {
    TopNav,
    SideMenu,
  },
  data() {
    return {
      myCourses: [],
      searchQuery: '',
      sortBy: 'name',
      loading: true, // Loading state
    };
  },
  computed: {
    filteredCourses() {
      let courses = this.myCourses || [];

      // Filter by search query
      if (this.searchQuery) {
        const searchLower = this.searchQuery.toLowerCase();
        courses = courses.filter(course => course.title.toLowerCase().includes(searchLower));
      }

      // Sort courses
      if (this.sortBy === 'name') {
        courses.sort((a, b) => a.title.localeCompare(b.title));
      } else if (this.sortBy === 'date_assigned') {
        courses.sort((a, b) => new Date(b.date) - new Date(a.date));
      } else if (this.sortBy === 'progress') {
        courses.sort((a, b) => b.progress - a.progress);
      }

      return courses;
    },
  },
  mounted() {
    this.fetchMyCourses();
  },
  methods: {
    getFullThumbnailUrl(thumbnail) {
      if (thumbnail && thumbnail.startsWith('http')) {
        return thumbnail;
      }
      const baseUrl = thumbnail ? process.env.VUE_APP_API_URL : `${process.env.VUE_APP_API_URL}/img`;
      const path = thumbnail ? thumbnail : '/default-course-image.jpg';
      return `${baseUrl}${path}`;
    },
    async fetchMyCourses() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query {
              myCourses {
                id
                title
                thumbnail
                category {
                  name
                }
                progress
                date_assigned
              }
            }
          `,
        });

        this.myCourses = response.data.data.myCourses || [];
      } catch (error) {
        console.error('Error fetching my courses:', error);
      } finally {
        this.loading = false; // Set loading to false after data is fetched
      }
    },
    formatDate(date) {
      if (!date) return '';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style scoped>
.bg-light-blue {
  background-color: #f0f8ff;
}

.container {
  max-width: 1200px;
}

.table-auto tbody tr:hover {
  background-color: #f9f9f9;
}

.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.text-green-500 {
  color: #38a169;
}

.text-red-500 {
  color: #e53e3e;
}
</style>
