<template>
    <div>
      <h2 class="text-3xl font-semibold mb-4">{{ unitData.title }}</h2>
      <p class="text-md leading-6">{{ unitData.content }}</p>
  
    </div>
  </template>
  
  <script>
  export default {
    props: {
      unitData: {
        type: Object,
        required: true,
      },
      courseId: {
        type: [String, Number],
        required: true,
      },
      unitId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        response: '',
      };
    },
    methods: {

    },
  };
  </script>
  