import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
  state: {
    user: null, // Holds the user data
  },
  mutations: {
    setUser(state, userData) {
      state.user = userData;
    },
    clearUser(state) {
      state.user = null;
    }
  },
  actions: {
    async fetchUser({ commit }) {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/user`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          commit('setUser', response.data); // Store the user data in the state
        } catch (error) {
          console.error('Failed to fetch user:', error);
        }
      }
    },
    clearUser({ commit }) {
      commit('clearUser');
    }
  },
  getters: {
    getUser: state => state.user,
    isAuthenticated: state => !!state.user
  }
});

export default store;
