<template>
    <button @click="handleClick"
            class="px-4 py-2 text-md text-gray-800 font-semibold hover:bg-gray-100 w-full flex items-center">
      <svg class="w-5 h-5 text-gray-800 dark:text-white mr-2" :aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
      viewBox="0 0 24 24">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
      :d="iconPath" />
    </svg>
      <span>{{ label }}</span>
    </button>
  </template>
  
  <script>
  export default {
    props: {
      label: {
        type: String,
        required: true
      },
      iconPath: {
        type: String,
        required: true
      },
      toggleDropdown: {
        type: Function,
        required: true
      }
    },
    methods: {
    handleClick() {
      this.$emit('option-clicked', this.label);
    }
  }
  };
  </script>