<!-- LogoutButton.vue -->

<template>
    <button @click="logout">Logout</button>
  </template>
  
  <script>
  import axios from 'axios';
  import router from '@/router'; // Import your router instance

  
  export default {
    methods: {
      async logout() {
        try {
          await axios.post(`${process.env.VUE_APP_API_URL}/api/logout`);
          // Remove token from local storage
          localStorage.removeItem('token');
          // Redirect to login page or perform any other action
          router.push('/login');
        } catch (error) {
          console.error('Logout error:', error);
          // Handle logout error, if any
        }
      }
    }
  };
  </script>
  