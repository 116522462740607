<template>
  <BasicHeader></BasicHeader>
  <section class="bg-gray-50 relative z-0">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

      <h1
        class="mb-7 text-3xl font-normal leading-none tracking-tight text-gray-900 md:text-4xl lg:text-5xl dark:text-white">
        Log in to your account

      </h1>

      <div
        class="w-full bg-white rounded-md shadow-xl dark:border md:mt-0 sm:max-w-xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">

          <form @submit.prevent="login" class="space-y-4 md:space-y-6">
            <div>
              <label for="email" class="block mb-2 text-lg font-medium capitalize text-gray-900 dark:text-white">Your
                email</label>
              <input v-model="email" type="email" name="email" id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full px-10 py-4 placeholder-gray-400"
                placeholder="Email address" required="">

            </div>
            <div>
              <label for="password" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Password</label>
              <input v-model="password" type="password" name="password" id="password" placeholder="Password"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-md rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full py-4 placeholder-gray-400"
                required="">
            </div>
            <div class="flex items-center justify-between">
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input id="remember" aria-describedby="remember" type="checkbox"
                    class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                    >
                </div>
                <div class="ml-3 text-sm">
                  <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
                </div>
              </div>
              <a href="#" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot
                password?</a>
            </div>
            <button type="submit"
              class="w-full text-white bg-blue-700 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-primary-300 font-semibold rounded-md text-sm px-5 py-2.5 text-center">SIGN IN</button>
            <p v-if="error" class="text-sm font-light text-red-500">{{ error }}</p>
            <p class="text-sm font-light text-gray-500 dark:text-gray-400">
              Don’t have an account yet? <router-link to="/register"
                class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</router-link>
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import BasicHeader from './headers/BasicHeader.vue'

export default {
  data() {
    return {
      email: '',
      password: '',
      error: null
    };
  },
  components: {
    BasicHeader,
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            mutation {
              loginUser(email: "${this.email}", password: "${this.password}") {
                user {
                  email
                }
                token
              }
            }
          `
        });

        // Check if the response has errors
        if (response.data.errors) {
          const error = response.data.errors[0];
          if (error.extensions && error.extensions.validation) {
            this.error = error.extensions.validation.email[0];
          } else {
            this.error = 'An error occurred. Please try again later.';
          }
          return;
        }

        // Destructure the response data to extract the token and user
        const { token, user } = response.data.data.loginUser;

        // Store the token and user in local storage
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));

        // Redirect the user to the dashboard
        this.$router.push('/dashboard');
      } catch (error) {
        console.error('Login error:', error);
        // Handle login error, e.g., show error message to the user
        this.error = 'An error occurred while logging in. Please try again later.';
      }
    }
  }
}
</script>
