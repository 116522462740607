<template>
  <div class="scorm-container">
    <!-- Hero Section -->
    <section class="hero bg-blue-600 text-white py-12 px-6 rounded-lg shadow-lg mb-8">
      <h1 class="text-4xl font-extrabold mb-4">{{ unitData.title }}</h1>
      <p class="text-lg mb-6">Get ready to dive into the interactive content! Click the button below to start your training.</p>

      <button 
        v-if="scormLaunchUrl" 
        @click="openPopup" 
        class="bg-yellow-500 text-black py-3 px-6 rounded-lg shadow hover:bg-yellow-600 transition">
        Start SCORM Player
      </button>
    </section>

    <!-- Popup for SCORM player -->
    <transition name="fade">
      <div v-if="showPopup" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
        <div class="relative bg-white w-3/4 h-3/4 max-w-4xl rounded-lg shadow-lg">
          <button @click="closePopup" class="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <!-- Loader Spinner -->
          <div v-if="loading" class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-40">
            <svg class="animate-spin h-16 w-16 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V2a10 10 0 00-10 10h2z"></path>
            </svg>
          </div>
          <iframe
            v-if="scormLaunchUrl"
            :src="scormLaunchUrl"
            width="100%"
            height="100%"
            frameborder="0"
            allowfullscreen
            @load="onIframeLoad"
          ></iframe>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    unitData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      scormLaunchUrl: null,
      showPopup: false,
      loading: false,  // Add loading state
    };
  },
  created() {
    this.setupScormPlayer();
  },
  methods: {
    setupScormPlayer() {
      if (this.unitData.scorm && this.unitData.scorm.entry_url) {
        const baseUrl = process.env.VUE_APP_API_URL || `${process.env.VUE_APP_API_URL}/api/scorm/player`;
        
        // Set SCORM context before loading the iframe
        window.API.setScormContext(this.unitData.scorm.scos[0].uuid, this.unitData.scorm.scos[0].id);
        
        this.scormLaunchUrl = `${baseUrl}/${this.unitData.scorm.uuid}/${encodeURIComponent(this.unitData.scorm.entry_url)}?scorm_uuid=${this.unitData.scorm.scos.uuid}&scorm_id=${this.unitData.scorm.scos.id}`;
      }
    },
    openPopup() {
      this.showPopup = true;
      this.loading = true; // Show loader when popup opens
    },
    closePopup() {
      this.showPopup = false;
    },
    onIframeLoad() {
      this.loading = false; // Hide loader when iframe finishes loading
    },
  },
};
</script>

<style scoped>
.scorm-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.hero {
  background: linear-gradient(135deg, #1d4ed8, #3b82f6);
  color: white;
}

.hero h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.hero p {
  font-size: 1.25rem;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Loader Spinner */
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
