<template>
  <div @click="selectCard" class="bg-white rounded-lg shadow-md p-4 mb-2 flex justify-between items-center cursor-pointer">
    <div class="flex items-center">
      <!-- Icon for card type -->
      <div class="flex-shrink-0">
        <!-- Use the icon from the card object -->
        <svg class="w-6 h-6 text-gray-800 dark:text-white mr-2" aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" :fill="card.iconFill"
          viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            :d="card.iconPath" />
        </svg>
      </div>
      <div class="ml-4">
        <!-- Card title -->
        <h3 class="text-lg font-semibold">{{ card.title }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  methods: {
    selectCard() {
      this.$emit('select');
    }
  }
};
</script>

<style>
/* Add your custom styles here */
.cursor-pointer {
  cursor: pointer;
}
</style>
