<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeDialog" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 flex items-center justify-center overflow-y-auto">
        <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95">
          <DialogPanel
            class="w-full max-w-[95vw] md:max-w-screen-md mx-auto overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-black h-[70vh] flex flex-col">

            <!-- Title with Underline -->
            <h2 class="text-2xl mb-6 font-semibold text-gray-800">Add {{ type }} question</h2>
            <hr class="border-b-1 border-gray-300 mb-4">

            <!-- Render different input fields based on question type -->
            <template v-if="type === 'Multiple Choice'">
              <textarea v-model="question" placeholder="Enter a question here" class="w-full mb-4"></textarea>
              <draggable v-model="options" :options="{ group: 'options', handle: '.handle' }" class="list-group"
                @start="onDragStart" @end="onDragEnd">
                <template #item="{ element }">
                  <div class="list-group-item relative mb-2">
                    <!-- Checkbox -->
                    <input type="checkbox" v-model="element.checked"
                      class="absolute left-2 top-1/2 transform -translate-y-1/2">
                    <!-- Draggable input with icon -->
                    <input v-model="element.text" type="text" placeholder="Option" class="w-full pl-8 pr-8 handle">

                    <!-- Delete button -->
                    <button @click="removeOption(element)"
                      class="text-red-500 hover:text-red-700 focus:outline-none absolute right-2 top-1/2 transform -translate-y-1/2">
                      <svg class="w-5 h-5 text-gray-500 hover:text-gray-800 dark:text-white" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                      </svg>
                    </button>
                  </div>
                </template>
              </draggable>
              <button @click="addOption" class="text-blue-500 hover:text-blue-700 focus:outline-none">Add
                Option</button>
            </template>

            <template v-else-if="type === 'Fill the Gaps'">
              <input v-model="question" type="text" placeholder="Enter a question here" class="w-full mb-4">
              <p class="text-sm text-gray-600 mb-2">Note: Compose the question and use [brackets] for the possible
                answers. For example, "The quick brown [fox] jumps over the lazy [dog]." OR "The [biggest|bigger] planet
                of our solar system is [jupiter|neptune|earth]." When you use | to offer multiple answers the first
                should be the correct one.</p>
            </template>

            <template v-else-if="type === 'Ordering'">
              <input v-model="question" type="text" placeholder="Enter a question here" class="w-full mb-4">
              <draggable v-model="options" :options="{ group: 'options', handle: '.handle' }" class="list-group"
                @start="onDragStart" @end="onDragEnd">
                <template #item="{ element }">
                  <div class="list-group-item relative mb-2">
                    <input v-model="element.text" type="text" placeholder="Option"
                      class="w-full pl-10 pr-8 handle cursor-pointer">
                    <svg class="h-5 w-5 absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"
                      aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                      viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h14" />
                    </svg>
                    <button @click="removeOption(element)"
                      class="text-red-500 hover:text-red-700 focus:outline-none absolute right-2 top-1/2 transform -translate-y-1/2">
                      <svg class="w-6 h-6 text-gray-500 hover:text-gray-800 dark:text-white" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                      </svg>
                    </button>
                  </div>
                </template>
              </draggable>
              <button @click="addOption" class="text-blue-500 hover:text-blue-700 focus:outline-none">Add
                Option</button>
            </template>

            <template v-if="type === 'Match the Pairs'">
              <input v-model="question" type="text" placeholder="Enter a question here" class="w-full mb-4">
              <p class="text-sm text-gray-600 mb-2">Note: Add possible answers in the correct order. We'll present them
                randomly for the end-user.</p>
              <div v-for="(pair, index) in pairs" :key="index" class="flex items-center group mb-2">
                <input v-model="pair.left" type="text" placeholder="Word or phrase"
                  class="w-1/2 border border-gray-300 rounded-md py-2 px-4">
                <span class="mx-2">=</span>
                <input v-model="pair.right" type="text" placeholder="Matching word"
                  class="w-1/2 border border-gray-300 rounded-md py-2 px-4">
                <button v-if="pairs.length > 1" @click="removePair(index)"
                  class="text-red-500 hover:text-red-700 focus:outline-none ml-2 opacity-0 group-hover:opacity-100 transition-opacity">
                  <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <button @click="addPair" class="text-blue-500 hover:text-blue-700 focus:outline-none mt-2">Add
                more</button>
            </template>

            <template v-if="type === 'Free Text'">
              <input v-model="question" type="text" placeholder="Enter a question here" class="w-full mb-4">
              <div class="flex items-center mb-4">
                <span class="text-sm text-gray-600 mr-2">Consider correct when accumulated points are greater or equal
                  to</span>
                <input v-model.number="requiredPoints" type="number"
                  class="w-20 border border-gray-300 rounded-md py-2 px-4">
              </div>
              <h3 class="text-lg font-semibold mb-2">Rules</h3>
              <div v-for="(rule, index) in rules" :key="index" class="flex flex-wrap items-center group mb-2">
                <span class="mr-2">When</span>
                <select v-model="rule.condition" class="border border-gray-300 rounded-md py-2 px-4 mr-2">
                  <option value="contains">contains</option>
                  <option value="does not contain">does not contain</option>
                </select>
                <span class="mr-2">the word</span>
                <input v-model="rule.word" type="text" placeholder="e.g., fast | quick"
                  class="w-1/4 border border-gray-300 rounded-md py-2 px-4 mr-2">
                <span class="mr-2">add</span>
                <input v-model.number="rule.points" type="number"
                  class="w-20 border border-gray-300 rounded-md py-2 px-4 mr-2">
                <span>points</span>
                <button v-if="rules.length > 1" @click="removeRule(index)"
                  class="text-red-500 hover:text-red-700 focus:outline-none ml-2 opacity-0 group-hover:opacity-100 transition-opacity">
                  <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <button @click="addRule" class="text-blue-500 hover:text-blue-700 focus:outline-none mt-2">Add extra
                rules</button>
            </template>

            <div class="mt-auto flex justify-end">
              <button @click="closeDialog"
                class="px-4 py-2 mr-2 text-sm font-medium text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded">Close</button>
              <button @click="saveQuestion"
                class="px-4 py-2 bg-primary-700 text-white rounded-md hover:bg-primary-600 focus:outline-none">Save</button>
            </div>

          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue';
import draggable from 'vuedraggable';

export default {
  data() {
    return {
      question: '',
      options: [{ text: '', checked: false }],
      pairs: [{ left: '', right: '' }], // Initial pair for Match the Pairs
      requiredPoints: 0,
      rules: [{ condition: 'contains', word: '', points: 0 }],
    };
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    draggable,
  },
  props: {
    isOpen: Boolean,
    type: String,
  },
  methods: {
    closeDialog() {
      this.$emit('close', false); // Emit event to close dialog
    },
    addOption() {
      this.options.push({ text: '', checked: false });
    },
    addPair() {
      this.pairs.push({ left: '', right: '' }); // Add a new empty pair
    },
    removeOption(element) {
      const index = this.options.findIndex(opt => opt === element);
      if (index !== -1) {
        this.options.splice(index, 1);
      }
    },
    removePair(index) {
      if (this.pairs.length > 1) {
        this.pairs.splice(index, 1); // Remove the pair at the given index
      }
    },
    addRule() {
      this.rules.push({ condition: 'contains', word: '', points: 0 }); // Add a new empty rule
    },
    removeRule(index) {
      if (this.rules.length > 1) {
        this.rules.splice(index, 1); // Remove the rule at the given index
      }
    },
    saveQuestion() {
      const questionData = {
        type: this.type,
        question: this.question || 'Default question text', // Ensure question is provided
        options: this.type === 'Multiple Choice' || this.type === 'Ordering' ? this.options : null, // Handle both Multiple Choice and Ordering
        pairs: this.type === 'Match the Pairs' ? this.pairs : null,
        fillTheGaps: this.type === 'Fill the Gaps' ? this.fillTheGaps : null,
        requiredPoints: this.type === 'Free Text' ? this.requiredPoints : null,
        rules: this.type === 'Free Text' ? this.rules : null,
      };

      this.$emit('save-question', questionData);
      this.closeDialog();
    },

  },
};
</script>
