<template>
  <CardHeader :title="title" font="lg" class="col-span-2"></CardHeader>

  <div class="grid grid-cols-2 gap-0 p-1 pb-0 text-center mt-2" style="height: calc(100% - 80px);">
    <div @click="comingSoon" class="bg-purple-100 m-1 flex flex-col items-center justify-center col-span-1 transition duration-300 ease-in-out hover:bg-purple-200 focus:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 rounded-md p-4 cursor-pointer">
      <svg class="w-9 h-9 mb-1 text-purple-500 dark:text-purple-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
        fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M16 12h4m-2 2v-4M4 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1c0 .6-.4 1-1 1H5a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      </svg>
      <span class="text-purple-500 dark:text-purple-300 text-lg font-semibold">Add user</span>
    </div>

    <div @click="comingSoon" class="bg-pink-100 m-1 flex flex-col items-center justify-center col-span-1 transition duration-300 ease-in-out hover:bg-pink-200 focus:bg-pink-200 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50 rounded-md p-4 cursor-pointer">
      <svg class="w-9 h-9 mb-1 text-pink-500 dark:text-pink-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
        fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 19V4c0-.6.4-1 1-1h12c.6 0 1 .4 1 1v13H7a2 2 0 0 0-2 2Zm0 0c0 1.1.9 2 2 2h12M9 3v14m7 0v4"/>
      </svg>
      <span class="text-pink-500 dark:text-pink-300 text-lg font-semibold">Add Course</span>
    </div>

    <div @click="comingSoon" class="bg-orange-100 m-1 flex flex-col items-center justify-center col-span-1 transition duration-300 ease-in-out hover:bg-red-200 focus:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 rounded-md p-4 cursor-pointer">
      <svg class="w-9 h-9 mb-1 text-orange-500 dark:text-red-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
        fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3a2.5 2.5 0 1 1 2-4.5M19.5 17h.5c.6 0 1-.4 1-1a3 3 0 0 0-3-3h-1m0-3a2.5 2.5 0 1 0-2-4.5m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3c0 .6-.4 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"/>
      </svg>
      <span class="text-orange-500 dark:text-red-300 text-lg font-semibold">Add Group</span>
    </div>

    <div @click="comingSoon" class="bg-green-100 m-1 flex flex-col items-center justify-center col-span-1 transition duration-300 ease-in-out hover:bg-green-200 focus:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md p-4 cursor-pointer">
      <svg class="w-9 h-9 mb-1 text-green-500 dark:text-green-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
        fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 8v8m0-8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8-8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 0a4 4 0 0 1-4 4h-1a3 3 0 0 0-3 3"/>
      </svg>
      
      <span class="text-green-500 dark:text-green-300 text-lg font-semibold">Add Category</span>
    </div>
  </div>
</template>

<script>
import CardHeader from '@/partials/CardHeader.vue';

export default {
  components: {
      CardHeader
  },
  props: {
      title: String
  },
  methods: {
      comingSoon() {
          alert('Coming soon!');
      }
  }
};
</script>
