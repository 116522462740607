<template>
  <div class="relative">
    <!-- Button to add a question -->
    <button @click="toggleDropdown" class="bg-red-500 text-white px-4 mb-4 py-2 rounded-md hover:bg-red-600 focus:outline-none w-full">
      Add a question
    </button>
    
    <!-- Dropdown menu -->
    <div v-if="isDropdownOpen" class="absolute z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
      <div class="py-1">
        <button @click="selectQuestionType('Multiple Choice')" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left">
          Multiple Choice
        </button>
        <button @click="selectQuestionType('Fill the Gaps')" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left">
          Fill the Gaps
        </button>
        <button @click="selectQuestionType('Ordering')" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left">
          Ordering
        </button>
        <button @click="selectQuestionType('Match the Pairs')" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left">
          Match the Pairs
        </button>
        <button @click="selectQuestionType('Free Text')" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left">
          Free Text
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectQuestionType(type) {
      this.isDropdownOpen = false;
      this.$emit('add-question', type); // Emit the selected question type to the parent component
    }
  }
};
</script>