<template>
  <div>
    <!-- Input box for title -->
    <input 
      v-model="iframeTitle"
      type="text" 
      placeholder="Add title for this unit" 
      class="w-full border border-gray-300 px-4 py-2 mb-4 focus:outline-none focus:border-blue-500"
      @input="emitData"
    />
  
    <!-- Input box for URL -->
    <div class="relative mb-4">
      <input 
        v-model="url"
        type="text" 
        placeholder="Paste the URL of the webpage you want to embed" 
        class="w-full border border-gray-300 px-4 py-2 pr-10 focus:outline-none focus:border-blue-500" 
        @keydown.enter="handleEnter"
      />
      <svg class="w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
        </svg>
    </div>
  
    <!-- iFrame -->
    <div class="mb-4">
      <iframe :src="url" frameborder="0" style="width: 100%; height: 500px;"></iframe>
    </div>
  
    <!-- Large content box -->
    <textarea 
      v-model="unitContent"
      placeholder="Add content" 
      class="w-full h-64 border border-gray-300 px-4 py-2 focus:outline-none focus:border-blue-500"
      @input="emitData"
    ></textarea>

    <!-- Section title: Complete unit -->
    <div class="flex items-center justify-between mb-4">
      <h3 class="text-lg font-semibold">Complete unit</h3>
      <!-- Dropdown for completion options -->
      <select 
        v-model="completionOption" 
        class="border border-gray-300 focus:outline-none px-4 py-2"
        @change="emitData"
      >
        <option value="">Select completion option</option>
        <option value="time">After a period of time</option>
        <option value="question">With a question</option>
      </select>
    </div>

    <!-- Conditional rendering based on completion option -->
    <div v-if="completionOption === 'time'" class="mb-4">
      <!-- Input box for time limit -->
      <input 
        v-model="timeLimit" 
        type="number" 
        placeholder="Seconds" 
        class="w-32 border border-gray-300 focus:outline-none px-4 py-2 mr-2"
        @input="emitData"
      />
    </div>

    <div v-else-if="completionOption === 'question'" class="mb-4">
      <TestButton @add-question="openQuestionDialog" />

      <div v-for="(question, index) in questions" :key="index" class="w-full bg-white shadow-md rounded-lg p-4 mb-4">
        <div class="flex justify-between items-center">
          <div>
            <h3 class="text-lg font-semibold">Question {{ index + 1 }}: {{ question.type }}</h3>
            <p>{{ question.question }}</p>
          </div>
          <div class="flex items-center space-x-2">
            <button @click="toggleQuestionDetails(index)">
              <svg v-if="!question.showDetails" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 15l-7-7-7 7" />
              </svg>
            </button>
            <button @click="deleteQuestion(index)">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500 hover:text-red-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>

        <!-- Question Details -->
        <div v-if="question.showDetails" class="mt-4">
          <div v-if="question.options" class="mb-2">
            <h4 class="text-md font-semibold">Options:</h4>
            <ul class="list-disc list-inside">
              <li v-for="(option, idx) in question.options" :key="idx">
                {{ option.text }} <span v-if="option.checked">(Correct)</span>
              </li>
            </ul>
          </div>
          <div v-if="question.pairs" class="mb-2">
            <h4 class="text-md font-semibold">Pairs:</h4>
            <ul class="list-disc list-inside">
              <li v-for="(pair, idx) in question.pairs" :key="idx">
                {{ pair.left }} = {{ pair.right }}
              </li>
            </ul>
          </div>
          <div v-if="question.fillTheGaps" class="mb-2">
            <h4 class="text-md font-semibold">Fill the Gaps:</h4>
            <p>{{ question.fillTheGaps }}</p>
          </div>
          <div v-if="question.rules" class="mb-2">
            <h4 class="text-md font-semibold">Rules:</h4>
            <ul class="list-disc list-inside">
              <li v-for="(rule, idx) in question.rules" :key="idx">
                When it {{ rule.condition }} the word "{{ rule.word }}", add {{ rule.points }} points.
              </li>
            </ul>
            <p>Required Points: {{ question.requiredPoints }}</p>
          </div>
        </div>
      </div>
    </div>   
    <TestDialog 
      v-if="isDialogOpen" 
      :isOpen="isDialogOpen" 
      :type="questionType" 
      @close="isDialogOpen = false" 
      @save-question="saveQuestion" 
    />
  </div>
</template>

<script>
import TestButton from '@/components/AddCourse/TestButton.vue';
import TestDialog from '@/components/AddCourse/TestDialog.vue';

export default {
  data() {
    return {
      iframeTitle: '', 
      url: '',
      unitContent: '', 
      completionOption: '', 
      timeLimit: null, 
      questions: [], 
      isDialogOpen: false, 
    };
  },
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    unit_order: {
      type: Number,
      required: true,
    },
  },
  components: {
    TestButton,
    TestDialog,
  },
  watch: {
    unitData: {
      handler(newData) {
        if (newData) {
          this.iframeTitle = newData.title || '';
          this.url = newData.url || '';
          this.unitContent = newData.content || '';
          this.completionOption = newData.completion_option || '';
          this.timeLimit = newData.time_limit || null;
          this.questions = newData.questions || [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleEnter(event) {
      if (event.key === 'Enter') {
        this.emitData();
      }
    },
    openQuestionDialog(type) {
      this.questionType = type;
      this.isDialogOpen = true;
    },
    saveQuestion(questionData) {
      this.questions.push({ ...questionData, showDetails: false });
      this.emitData();
    },
    deleteQuestion(index) {
      this.questions.splice(index, 1);
      this.emitData();
    },
    toggleQuestionDetails(index) {
      this.questions[index].showDetails = !this.questions[index].showDetails;
    },
    emitData() {
      const iframeData = {
        title: this.iframeTitle,
        url: this.url,
        unit_order: this.unit_order,
        content: this.unitContent,
        completion_option: this.completionOption,
        time_limit: this.timeLimit,
        questions: this.questions,
      };
      this.$emit('data-updated', iframeData);
    }
  }
};
</script>
