<template>
    <div class="flex flex-wrap items-center px-4 pt-3 pb-0">
        <div class="relative w-full max-w-full flex-grow flex-1 border-b pb-3">
            <h3 class="text-primary-900 font-semibold text-2xl dark:text-gray-50" v-if="font == 'lg'">{{ title }}</h3>
            <h3 class="font-bold text-base text-gray-900 dark:text-gray-50" v-else>{{ title }}</h3>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        title: String,
        font: String
    }
};
</script>
