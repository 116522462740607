<template>
    <!-- External resources -->
    <div class="mt-8 mx-4">
        <div
            class="p-4 bg-blue-50 dark:bg-gray-800 dark:text-gray-50 border border-blue-500 dark:border-gray-500 rounded-lg shadow-md">
            <h4 class="text-lg font-semibold">Quick Links:
            </h4>
            <ul>
                <li class="mt-3">
                    <a class="flex items-center text-blue-700 dark:text-gray-100"
                        href="#" >
                        <svg width="20" height="20" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            class="transform transition-transform duration-500 ease-in-out">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <span class="inline-flex pl-2">View all courses</span>
                    </a>
                </li>
                <li class="mt-2">
                    <a class="flex items-center text-blue-700 dark:text-gray-100"
                        href="#" >
                        <svg width="20" height="20" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            class="transform transition-transform duration-500 ease-in-out">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <span class="inline-flex pl-2">Add a user</span>
                    </a>
                </li>
                <li class="mt-2">
                    <a class="flex items-center text-blue-700 dark:text-gray-100"
                        href="#" >
                        <svg width="20" height="20" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            class="transform transition-transform duration-500 ease-in-out">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <span class="inline-flex pl-2">Create a course</span>
                    </a>
                </li>
                <li class="mt-2">
                    <a class="flex items-center text-blue-700 dark:text-gray-100"
                        href="#" >
                        <svg width="20" height="20" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            class="transform transition-transform duration-500 ease-in-out">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <span class="inline-flex pl-2">Reporting</span>
                    </a>
                </li>
                <li class="mt-2">
                    <a class="flex items-center text-blue-700 dark:text-gray-100"
                        href="#" >
                        <svg width="20" height="20" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            class="transform transition-transform duration-500 ease-in-out">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <span class="inline-flex pl-2">Create a branch</span>
                    </a>
                </li>
                <li class="mt-2">
                    <a class="flex items-center text-blue-700 dark:text-gray-100" href="#"
                        >
                        <svg width="20" height="20" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            class="transform transition-transform duration-500 ease-in-out">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <span class="inline-flex pl-2">View our Knowledge base</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>