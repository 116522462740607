<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900">
    <TopNav></TopNav>

    <!-- Sidebar -->
    <SideMenu></SideMenu>

    <main class="p-4 md:ml-64 h-auto pt-20">
      <!-- grid 1 -->
      <div class="flex flex-row mb-4 mt-3">
        <!-- Main content area with variable grid layout -->
        <div class="basis-full lg:basis-10/12 mx-auto max-w-screen-3xl pr-5">
          <!-- Responsive grid layout -->
            Learner Dashboard coming soon

           
        </div>
        

      </div>
    </main>
  </div>
</template>

<script>
import TopNav from '@/components/headers/TopNav.vue';
import SideMenu from '@/components/LearnerSideMenu.vue';

export default {
  components: {
      TopNav,
      SideMenu,

  },
};
</script>
