<template>
  <div>
    <h2 class="text-2xl font-bold mb-4">{{ unitData.title }}</h2>
    
    <!-- iFrame for displaying the content -->
    <iframe 
      :src="unitData.url" 
      class="w-full rounded-lg shadow-md" 
      frameborder="0"
      style="height:900px;"
      allowfullscreen
    ></iframe>
  
  </div>
</template>

<script>
export default {
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    courseId: {
      type: [String, Number],
      required: true,
    },
    unitId: {
      type: [String, Number],
      required: true,
    },
  },

};
</script>

<style scoped>
.iframe-container {
  position: relative;
  padding-bottom: 56.25%; /* Aspect ratio: 16:9 */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
