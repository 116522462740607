import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'; // Import your Vuex store
import LoginComponent from '../components/LoginComponent.vue';
import RegisterComponent from '../components/RegisterComponent.vue';
import LogoutButton from '../components/LogoutButton.vue';
import DashboardComponent from '../components/DashboardComponent.vue';
import LearnerDashboard from '../components/learner/LearnerDashboard.vue';
import CoursesTable from '../components/CoursesTable.vue';
import AddCourse from '../components/AddCourse.vue';
import MyCourses from '../components/learner/MyCourses.vue';
import MyProgress from '../components/learner/MyProgress.vue';
import MyBadges from '../components/learner/MyBadges.vue';
import MyCertificates from '../components/learner/MyCertificates.vue';
import CourseCatalog from '../components/learner/CourseCatalog.vue';
import ViewCourse from '../components/learner/ViewCourse.vue'; 
import CoursePage from '../components/learner/CoursePage.vue'; 

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardComponent,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/courses',
    name: 'Courses',
    component: CoursesTable,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/addcourse',
    name: 'Add Courses',
    component: AddCourse,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginComponent,
    meta: { requiresAuth: false }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutButton,
    meta: { requiresAuth: true }
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterComponent,
    meta: { requiresAuth: false }
  },
  // Learner-specific routes
  {
    path: '/learner/dashboard',
    name: 'Learner Dashboard',
    component: LearnerDashboard,
    meta: { requiresAuth: true, roles: ['learner'] }
  },
  {
    path: '/learner/courses',
    name: 'My Courses',
    component: MyCourses,
    meta: { requiresAuth: true, roles: ['learner'] }
  },
  {
    path: '/learner/progress',
    name: 'My Progress',
    component: MyProgress,
    meta: { requiresAuth: true, roles: ['learner'] }
  },
  {
    path: '/learner/badges',
    name: 'My Badges',
    component: MyBadges,
    meta: { requiresAuth: true, roles: ['learner'] }
  },
  {
    path: '/learner/certificates',
    name: 'My Certificates',
    component: MyCertificates,
    meta: { requiresAuth: true, roles: ['learner'] }
  },
  {
    path: '/learner/course-catalog',
    name: 'Course Catalog',
    component: CourseCatalog,
    meta: { requiresAuth: true, roles: ['learner'] }
  },
  {
    path: '/learner/catalog/course/:id', // Dynamic route for viewing a specific course
    name: 'ViewCourse',
    component: ViewCourse,
    props: true, // Allows route parameters to be passed as props to the component
    meta: { requiresAuth: true, roles: ['learner'] } // Ensure the user is authenticated and is a learner
  },
  {
    path: '/learner/course/:course/unit/:id?',
    name: 'course',
    component: CoursePage,
    props: true, 
    meta: { requiresAuth: true, roles: ['learner'] } 
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Use createWebHistory for Vue 3
  routes,
});


router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const allowedRoles = to.meta.roles;

  if (requiresAuth && !token) {
    // Store the intended route in localStorage
    localStorage.setItem('intendedRoute', to.fullPath);
    next('/login');
  } else if (requiresAuth && token) {
    try {
      // Fetch user info and store it in Vuex
      await store.dispatch('fetchUser');
      const userRole = store.state.user.role; // Get user role from Vuex state

      if (allowedRoles && !allowedRoles.includes(userRole)) {
        // If the role is not allowed, redirect to a fallback page
        next('/login');
      } else {
        // Role is authorized, proceed to the route
        next();
      }
    } catch (error) {
      // If there's an error, likely due to an invalid token or a failed API call, redirect to login
      localStorage.removeItem('token'); // Optionally remove the invalid token
      next('/login');
    }
  } else {
    // If the route does not require authentication, proceed
    next();
  }
});

export default router;
