<!-- RegisterComponent.vue -->
<template>
  <div>
    <h2>Register</h2>
    <form @submit.prevent="register">
      <label>Name:</label>
      <input type="text" v-model="name">
      <label>Email:</label>
      <input type="email" v-model="email">
      <label>Password:</label>
      <input type="password" v-model="password">
      <button type="submit">Register</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      name: '',
      email: '',
      password: ''
    };
  },
  methods: {
    async register() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
mutation {
  registerUser(
    name: "${this.name}", 
    email: "${this.email}", 
    password: "${this.password}",
    role: "learner"
  ) {
    name
    email
  }
}

      `
        });

        console.log(response)
        // Use destructuring assignment to extract the user directly
        const { user } = response.data.data.registerUser;
        // Handle successful registration, e.g., show success message
        console.log('Registration successful. User:', user);
      } catch (error) {
        console.error('Registration error:', error);
        // Handle registration error, e.g., show error message
      }
    }

  }
};
</script>