<template>
  <div class="assignment-unit p-6 bg-white shadow-md rounded-lg">
    <!-- Assignment Title and Content -->
    <h2 class="text-2xl font-bold mb-4">{{ unitData.title }}</h2>
    <p class="mb-6 text-lg text-gray-700">{{ unitData.content }}</p>

    <!-- Selection Tiles (Show if no option is selected) -->
    <div v-if="!selectedOption" class="flex space-x-4">
      <!-- Submit Text Option -->
      <div 
        @click="selectOption('text')" 
        class="flex-1 flex items-center justify-center h-64 bg-blue-100 cursor-pointer hover:bg-blue-200 transition duration-200 shadow-lg"
      >
        <div class="text-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-20 h-20 text-blue-600 mb-4 mx-auto" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M20 6h-8l-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2z"></path>
            <line x1="12" y1="10" x2="12" y2="16"></line>
            <line x1="9" y1="13" x2="15" y2="13"></line>
          </svg>
          <p class="text-lg font-semibold text-blue-800">Submit Text</p>
        </div>
      </div>

      <!-- Upload File Option -->
      <div 
        @click="selectOption('upload')" 
        class="flex-1 flex items-center justify-center h-64 bg-green-100 cursor-pointer hover:bg-green-200 transition duration-200 shadow-lg"
      >
        <div class="text-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-20 h-20 text-green-600 mb-4 mx-auto" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="12" y1="8" x2="12" y2="16"></line>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </svg>
          <p class="text-lg font-semibold text-green-800">Upload File</p>
        </div>
      </div>
    </div>

    <!-- Text Submission Form (Show if text option is selected or savedText exists) -->
    <div v-if="selectedOption === 'text'" class="mt-6">
      <button @click="backToSelection" class="text-blue-500 mb-4 flex items-center">
        <svg class="h-5 w-5 mr-1" fill="none" stroke="currentColor" stroke-width="2" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"></path>
        </svg>
        Cancel and go back to selection
      </button>
      <h3 class="text-lg font-semibold mb-4">Submit Your Assignment as Text</h3>
      <textarea v-model="textSubmission" rows="8" class="w-full p-4 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"></textarea>
      <button @click="submitAssignment('text')" class="mt-4 bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700">Submit</button>
    </div>

    <!-- File Upload Form (Show if upload option is selected or savedFile exists) -->
    <div v-if="selectedOption === 'upload'" class="mt-6">
      <button @click="backToSelection" class="text-blue-500 mb-4 flex items-center">
        <svg class="h-5 w-5 mr-1" fill="none" stroke="currentColor" stroke-width="2" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"></path>
        </svg>
        Cancel and go back to selection
      </button>
      <h3 class="text-lg font-semibold mb-4">Upload Your Assignment File</h3>
      <input type="file" @change="handleFileUpload" accept=".pdf,.docx,.xlsx,.png,.jpg,.mp4,.zip" />
      <p class="mt-2 text-sm text-gray-500">Accepted files: PDF, DOCX, XLSX, PNG, JPG, MP4, ZIP (max 100MB)</p>
      <button @click="submitAssignment('upload')" class="mt-4 bg-green-600 text-white px-6 py-2 rounded-md hover:bg-green-700">Submit</button>
      <!-- Display uploaded file as a download button -->
      <div v-if="savedFile" class="mt-4">
        <a :href="getFullFileUrl(savedFile)" target="_blank" download class="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400">
          Download Uploaded File
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    courseId: {
      type: [String, Number],
      required: true,
    },
    unitId: {
      type: [String, Number],
      required: true,
    },
    savedResponses: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedOption: null, // Tracks whether 'text' or 'upload' is selected
      textSubmission: '', // Holds the user's text submission
      fileSubmission: null, // Holds the uploaded file
      savedText: '', // Holds saved text submission
      savedFile: '', // Holds saved file path
    };
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
    },

    backToSelection() {
      this.selectedOption = null;
      this.textSubmission = ''; // Clear the text when going back to selection
      this.fileSubmission = null; // Clear the file submission
    },

    handleFileUpload(event) {
      this.fileSubmission = event.target.files[0];
    },

    async submitAssignment(type) {
      if (type === 'text' && this.textSubmission.trim()) {
        this.emitAnswer({ type: 'AssignmentText', content: this.textSubmission, file: null });
        this.savedText = this.textSubmission; // Save the text submission
        this.savedFile = null; // Clear any saved file if text is submitted
      } else if (type === 'upload' && this.fileSubmission) {
        await this.uploadFile();
        this.savedText = null; // Clear any saved text if file is uploaded
      } else {
        alert('Please provide a valid submission.');
      }
    },

    async uploadFile() {
      try {
        const formData = new FormData();
        formData.append('file', this.fileSubmission);
        formData.append('unitId', this.unitId);
        formData.append('courseId', this.courseId);

        const response = await axios.post('/api/upload-assignment', formData);
        const filePath = response.data.filePath;

        this.emitAnswer({ type: 'AssignmentFile', content: null, file: filePath });
        this.savedFile = filePath; // Save the file path
        this.savedText = null; // Clear any saved text
      } catch (error) {
        alert('File upload failed.');
        console.error('File upload error:', error);
      }
    },

    emitAnswer(submission) {
      const answerObject = {
        type: submission.type,
        content: submission.content || null,  // Use 'content' for text submissions
        file: submission.file || null,        // Use 'file' for file submissions
      };

      this.$emit('set-answer', this.unitId, answerObject);
      console.log('Emitting assignment submission:', answerObject);
    },

    getFullFileUrl(filePath) {
      const baseUrl = process.env.VUE_APP_API_URL;
      return `${baseUrl}/storage/${filePath}`;
    },
  },
  mounted() {
    // Restore saved responses if they exist
    if (this.savedResponses[this.unitId]) {
      const savedResponse = this.savedResponses[this.unitId];
      if (savedResponse.content) {
        this.savedText = savedResponse.content; // Restore text submission
        this.textSubmission = savedResponse.content; // Show in textarea
        this.selectedOption = 'text'; // Select text option
      } else if (savedResponse.file) {
        this.savedFile = savedResponse.file; // Restore file submission
        this.selectedOption = 'upload'; // Select upload option
      }
    }
  },
};
</script>

<style scoped>
.assignment-unit {
  max-width: 800px;
  margin: 0 auto;
}
</style>
