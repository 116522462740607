<template>
  <div class="flex flex-col space-y-4">
    <!-- Full-width input box for the title -->
    <input 
      v-model="videoTitle"
      type="text" 
      placeholder="Enter unit title here" 
      class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2"
      @input="emitVideoData"
    />
{{ youtubeLinkInput }}
    <div v-if="!youtubeLink && !uploadedVideo && !isYouTubeSelected && !isFileUploadSelected" class="flex space-x-4">
      <!-- Container for "Use a YouTube video" option -->
      <div 
        @click="selectYouTube" 
        class="flex-1 flex items-center justify-center h-64 bg-gray-200 cursor-pointer"
      >
        <div class="text-center">
          <!-- Icon for using a YouTube video -->
          <svg class="w-20 h-20 text-gray-600 mb-4 mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z" clip-rule="evenodd"/>
          </svg>
          <p class="mx-auto">Use a YouTube video</p>
        </div>
      </div>

      <!-- Container for "Upload a video file" option -->
      <div 
        @click="selectFileUpload" 
        class="flex-1 flex items-center justify-center h-64 bg-gray-200 cursor-pointer"
      >
        <div class="text-center">
          <!-- Icon for uploading a video file -->
          <svg xmlns="http://www.w3.org/2000/svg" class="w-20 h-20 text-gray-600 mb-4 mx-auto" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
            <line x1="12" y1="8" x2="12" y2="16" />
            <line x1="8" y1="12" x2="16" y2="12" />
          </svg>
          <p class="mx-auto">Upload a video file</p>
        </div>
      </div>
    </div>

    <!-- Input for YouTube link -->
    <div v-if="isYouTubeSelected && !youtubeLink" class="mt-4 relative">
      <input 
        v-model="youtubeLinkInput" 
        type="text" 
        placeholder="Enter YouTube video link" 
        class="w-full border-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2"
      />
      <button @click="embedYouTubeVideo" class="mt-2 bg-blue-500 text-white px-4 py-2 rounded">Embed Video</button>
      <button 
        @click="resetYouTubeSelection" 
        class="absolute top-0 right-0 mt-3 mr-3 text-gray-500 hover:text-gray-700"
      >
        <svg class="w-5 h-5 text-gray-700 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6"/>
        </svg>
      </button>
    </div>

    <!-- Embed YouTube video with full width and height -->
    <div v-if="youtubeLink" class="relative" style="padding-bottom: 56.25%; height: 0; overflow: hidden;">
      <button 
        @click="removeVideo" 
        class="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-bl z-10"
      >
        Remove
      </button>
      <iframe 
        :src="youtubeEmbedUrl" 
        class="absolute top-0 left-0 w-full h-full" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
      </iframe>
    </div>

    <!-- Video upload input and player -->
    <div v-if="isFileUploadSelected && !uploadedVideo" class="mt-4 relative">
      <input 
        type="file" 
        @change="handleVideoUpload" 
        accept="video/*" 
        class="w-full border-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2"
      />
      <button 
        @click="resetFileUploadSelection" 
        class="absolute top-0 right-0 mt-3 mr-3 text-gray-500 hover:text-gray-700"
      >
        <svg class="w-5 h-5 text-gray-700 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6"/>
        </svg>
      </button>
    </div>

    <!-- Display the uploaded video -->
    <div v-if="uploadedVideo" class="relative" style="padding-bottom: 56.25%; height: 0; overflow: hidden;">
      <button 
        @click="removeVideo" 
        class="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-bl z-10"
      >
        Remove
      </button>
      <video controls class="absolute top-0 left-0 w-full h-full">
        <source :src="uploadedVideo" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>

    <!-- Large content box -->
    <textarea 
      v-model="unitContent" 
      placeholder="Add content" 
      class="w-full h-64 border-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2 mt-4"
      @input="emitVideoData"
    ></textarea>

    <!-- Section title: Complete unit -->
    <div class="flex items-center justify-between mb-4">
      <h3 class="text-lg font-semibold">Complete unit</h3>
      <!-- Dropdown for completion options -->
      <select 
        v-model="completionOption" 
        class="border border-gray-300 focus:outline-none px-4 py-2"
        @change="emitVideoData"
      >
        <option value="">Select completion option</option>
        <option value="time">After a period of time</option>
        <option value="question">With a question</option>
      </select>
    </div>

    <!-- Conditional rendering based on completion option -->
    <div v-if="completionOption === 'time'" class="mb-4">
      <!-- Input box for time limit -->
      <input 
        v-model="timeLimit" 
        type="number" 
        placeholder="Seconds" 
        class="w-32 border border-gray-300 focus:outline-none px-4 py-2 mr-2"
        @input="emitVideoData"
      />
    </div>

    <div v-else-if="completionOption === 'question'" class="mb-4">
      <TestButton @add-question="openQuestionDialog" />

      <div v-for="(question, index) in questions" :key="index" class="w-full bg-white shadow-md rounded-lg p-4 mb-4">
        <div class="flex justify-between items-center">
          <div>
            <h3 class="text-lg font-semibold">Question {{ index + 1 }}: {{ question.type }}</h3>
            <p>{{ question.question }}</p>
          </div>
          <div class="flex items-center space-x-2">
            <button @click="toggleQuestionDetails(index)">
              <svg v-if="!question.showDetails" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 15l-7-7-7 7" />
              </svg>
            </button>
            <button @click="deleteQuestion(index)">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500 hover:text-red-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>

        <!-- Question Details -->
        <div v-if="question.showDetails" class="mt-4">
          <div v-if="question.options" class="mb-2">
            <h4 class="text-md font-semibold">Options:</h4>
            <ul class="list-disc list-inside">
              <li v-for="(option, idx) in question.options" :key="idx">
                {{ option.text }} <span v-if="option.checked">(Correct)</span>
              </li>
            </ul>
          </div>
          <div v-if="question.pairs" class="mb-2">
            <h4 class="text-md font-semibold">Pairs:</h4>
            <ul class="list-disc list-inside">
              <li v-for="(pair, idx) in question.pairs" :key="idx">
                {{ pair.left }} = {{ pair.right }}
              </li>
            </ul>
          </div>
          <div v-if="question.fillTheGaps" class="mb-2">
            <h4 class="text-md font-semibold">Fill the Gaps:</h4>
            <p>{{ question.fillTheGaps }}</p>
          </div>
          <div v-if="question.rules" class="mb-2">
            <h4 class="text-md font-semibold">Rules:</h4>
            <ul class="list-disc list-inside">
              <li v-for="(rule, idx) in question.rules" :key="idx">
                When it {{ rule.condition }} the word "{{ rule.word }}", add {{ rule.points }} points.
              </li>
            </ul>
            <p>Required Points: {{ question.requiredPoints }}</p>
          </div>
        </div>
      </div>
    </div>   
    <TestDialog 
      v-if="isDialogOpen" 
      :isOpen="isDialogOpen" 
      :type="questionType" 
      @close="isDialogOpen = false" 
      @save-question="saveQuestion" 
    />
  </div>
</template>

<script>
import TestButton from '@/components/AddCourse/TestButton.vue';
import TestDialog from '@/components/AddCourse/TestDialog.vue';

export default {
  data() {
    return {
      videoTitle: '', 
      isYouTubeSelected: false, 
      isFileUploadSelected: false,
      youtubeLinkInput: '', 
      youtubeLink: '', 
      uploadedVideo: '', 
      videoFile: null, // To store the actual video file

      unitContent: '', 
      completionOption: '', 
      timeLimit: null, 
      questions: [], 
      isDialogOpen: false, 
    };
  },
  props: {
    unit_order: {
      type: Number,
      required: true,
    },
    unitData: {
      type: Object,
      required: true,
    },
  },
  watch: {
  unitData: {
    handler(newData) {
      if (newData) {
        this.videoTitle = newData.title || '';
        this.youtubeLink = newData.youtube_link || '';
        this.youtubeLinkInput = ''; // Reset youtubeLinkInput when new unitData is loaded
        this.uploadedVideo = newData.video_path || '';
        this.unitContent = newData.content || '';
        this.completionOption = newData.completion_option || '';
        this.timeLimit = newData.time_limit || null;
        this.questions = newData.questions || [];
      }
    },
    immediate: true,
    deep: true,
  }
},
  components: {
    TestButton,
    TestDialog,
  },
  computed: {
    youtubeEmbedUrl() {
      // Convert YouTube link to embed link
      const url = new URL(this.youtubeLink);
      const videoId = url.searchParams.get('v');
      return `https://www.youtube.com/embed/${videoId}`;
    }
  },
  methods: {
    openQuestionDialog(type) {
      this.questionType = type;
      this.isDialogOpen = true;
    },
    saveQuestion(questionData) {
      this.questions.push({ ...questionData, showDetails: false }); // Save the new question to the array
      this.emitVideoData(); // Emit the data-updated event to the parent

    },
    deleteQuestion(index) {
      this.questions.splice(index, 1); // Delete question
      this.emitVideoData(); // Emit the data-updated event to the parent
    },
    toggleQuestionDetails(index) {
      this.questions[index].showDetails = !this.questions[index].showDetails; // Toggle question details
    },
    selectYouTube() {
      this.isYouTubeSelected = true;
    },
    embedYouTubeVideo() {
    if (this.youtubeLinkInput) {
      this.youtubeLink = this.youtubeLinkInput; // Set youtubeLink from youtubeLinkInput
      this.youtubeLinkInput = ''; // Reset youtubeLinkInput after embedding
      this.isYouTubeSelected = false; // Hide the input fields
      this.emitVideoData(); // Emit the data-updated event to the parent
    }
  },
  removeVideo() {
    this.youtubeLink = '';
    this.youtubeLinkInput = ''; // Ensure the input field is cleared
    this.uploadedVideo = '';
    this.isYouTubeSelected = false;
    this.isFileUploadSelected = false;
    this.emitVideoData();
  },
    resetYouTubeSelection() {
      this.youtubeLinkInput = ''; // Clear the YouTube link input
      this.isYouTubeSelected = false; // Reset YouTube selection
    },
    selectFileUpload() {
      this.isFileUploadSelected = true;
    },
    handleVideoUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadedVideo = URL.createObjectURL(file); // Temporary URL for preview
        this.videoFile = file; // Save the actual file object
        this.emitVideoData(); // Emit data to parent
      }
    },
    resetFileUploadSelection() {
      this.isFileUploadSelected = false; // Reset File Upload selection
      this.uploadedVideo = ''; // Clear the uploaded video
      this.emitVideoData(); // Emit the data-updated event to the parent
    },
    emitVideoData() {
      const videoData = {
        title: this.videoTitle,
        content: this.unitContent,
        unit_order: this.unit_order,
        video_path: this.uploadedVideo, // Video file path (temporary url or actual url after upload)
        youtube_link: this.youtubeLink, // YouTube link if used
        completion_option: this.completionOption,
        time_limit: this.timeLimit,
        questions: this.questions, // Add the questions array
        file: this.videoFile, // Pass the file for uploading
      };
      this.$emit('data-updated', videoData);
    },
  }
};
</script>
