<template>
  <div v-if="isActive" class="test-slide p-6 bg-white shadow-md rounded-lg mb-6">
    <h3 class="text-md text-gray-800 mb-4" v-if="test.type !== 'Fill the Gaps'">
      {{ test.question }}
    </h3>
    <h3 class="text-md text-gray-800 mb-4" v-else-if="test.type == 'Fill the Gaps'">Complete the Sentences by Filling in
      the Gaps</h3>

    <!-- Multiple Choice Section -->
    <div v-if="test.type === 'Multiple Choice'">
      <div v-for="(option, index) in test.options" :key="index" class="mb-3">
        <label class="flex items-center space-x-2 text-lg text-gray-700">
          <input type="radio" :name="`test-${test.id}`" :value="option.text" v-model="selectedOption"
            @change="emitAnswer" class="h-5 w-5 text-blue-600 border-gray-300 focus:ring-2 focus:ring-blue-500" />
          <span>{{ option.text }}</span>
        </label>
      </div>
    </div>

    <!-- Ordering Section -->
    <div v-if="test.type === 'Ordering'">
      <draggable v-model="userOrder" @end="emitAnswer" class="ordering-list">
        <template #item="{ element }">
          <div class="mb-2 p-2 bg-gray-100 rounded shadow">{{ element.text }}</div>
        </template>
      </draggable>
    </div>

    <!-- Free Text Section -->
    <div v-if="test.type === 'Free Text'">
      <textarea v-model="freeTextAnswer" @input="emitAnswer" rows="4"
        class="w-full p-2 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 text-gray-800 text-lg"
        placeholder="Write your response here..."></textarea>
    </div>

<!-- Fill the Gaps Section -->
<div v-if="test.type === 'Fill the Gaps'">
  <span v-for="(part, index) in renderedQuestion" :key="index">
    <span v-if="typeof part === 'string'" class="text-lg text-gray-800">{{ part }}</span>
    <span v-else class="inline-block mx-2">
      <select 
        v-model="userSelections[part.index]" 
        @change="emitAnswer" 
        class="p-2 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 text-gray-800 text-lg"
      >
        <option value="" disabled>Select an option</option>
        <!-- Use shuffled options from allGapOptions -->
        <option v-for="option in allGapOptions[part.index]" :key="option" :value="option">{{ option }}</option>
      </select>
    </span>
  </span>
</div>

<!-- Match the Pairs Section -->
<div v-if="test.type === 'Match the Pairs'" class="match-pairs-section">
  <div class="match-pairs-grid">
    <!-- Left Column (Pairs Left) -->
    <div class="pairs-left">
      <ul>
        <li v-for="(pair, index) in test.pairs" :key="index" class="text-lg text-gray-800 p-2 bg-gray-100 rounded-lg">
          {{ pair.left }}
        </li>
      </ul>
    </div>
    
    <!-- Right Column (Dropdowns for matching) -->
    <div class="pairs-right">
      <ul>
        <li v-for="(pair, index) in test.pairs" :key="index">
          <!-- Display selected option and the remove button -->
          <div v-if="userSelections[index]">
            <span class="bg-gray-200 p-2 rounded-lg">{{ userSelections[index] }}</span>
            <button class="ml-2 text-red-600" @click="clearSelection(index)">&times;</button>
          </div>
          
          <!-- Show dropdown if no option is selected -->
          <div v-else>
            <select v-model="userSelections[index]" @change="emitAnswer" class="p-2 border border-gray-300 rounded-md">
              <option value="" disabled>Select a match</option>
              <option v-for="option in filteredRightOptions(index)" :key="option" :value="option">{{ option }}</option>
            </select>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  props: {
    test: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    savedAnswers: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      selectedOption: null, // For multiple choice
      userSelections: Array(this.getGapCount()).fill(null), // Initialize for gaps
      freeTextAnswer: '', // For Free Text answer
      userOrder: this.test.type === 'Ordering' ? this.shuffleArray([...this.test.options || []]) : [], // Initialize only for Ordering type
      rightOptions: this.shuffleArray(this.test.pairs ? this.test.pairs.map(pair => pair.right) : []), // Initialize right options for matching pairs
    };
  },
  components: {
    draggable,
  },
  mounted() {
    this.restoreSavedAnswers(); // Restore answers when the component mounts
  },
  watch: {
    savedAnswers: {
      immediate: true, // Trigger immediately when savedAnswers is received
      handler() {
        this.restoreSavedAnswers(); // Restore answers when savedAnswers change
      }
    }
  },
  computed: {
    // Process the question and replace gaps with dropdowns
    renderedQuestion() {
    const gapRegex = /\[(.*?)\]/g;
    let questionParts = [];
    let lastIndex = 0;
    let gapIndex = 0;

    // Split the question string based on gaps
    this.test.question.replace(gapRegex, (match, gapContent, offset) => {
      questionParts.push(this.test.question.slice(lastIndex, offset));
      questionParts.push({ index: gapIndex });
      gapIndex++;
      lastIndex = offset + match.length;
    });

    questionParts.push(this.test.question.slice(lastIndex));
    return questionParts;
  },

  allGapOptions() {
    const gapRegex = /\[(.*?)\]/g;
    const matches = this.test.question.match(gapRegex);

    // For each gap, split the options, shuffle them, and return them
    return matches.map(match => {
      const gapOptions = match.replace(/\[|\]/g, '').split('|'); // Remove brackets and split options

      // If there is only one option (e.g., [fox]), mix with other single-option gaps
      if (gapOptions.length === 1) {
        const allSingleOptions = this.test.question
          .match(/\[(.*?)\]/g)
          .map(gap => gap.replace(/\[|\]/g, '').split('|'))
          .flat();

        return this.shuffleArray(Array.from(new Set(allSingleOptions))); // Mix with all single-option gaps
      }

      // If there are multiple options, shuffle them and ensure the first is the correct answer
      return this.shuffleArray(gapOptions);
    });
  }
  },
  methods: {
    // Function to count the number of gaps in the question
    getGapCount() {
      const gapRegex = /\[(.*?)\]/g;
      const gaps = this.test.question.match(gapRegex);
      return gaps ? gaps.length : 0;
    },

    shuffleArray(array) {
      return array.sort(() => Math.random() - 0.5);
    },

    // Restore saved answers
    restoreSavedAnswers() {
      // Restore multiple choice option if saved
      if (this.test.type === 'Multiple Choice' && this.savedAnswers.selectedOption) {
        this.selectedOption = this.savedAnswers.selectedOption;
      }

      if (this.test.type === 'Free Text' && this.savedAnswers.freeTextAnswer) {
        this.freeTextAnswer = this.savedAnswers.freeTextAnswer;
      }

      // Restore fill the gaps selections if saved
      if (this.test.type === 'Fill the Gaps' && this.savedAnswers.userSelections) {
        this.userSelections = [...this.savedAnswers.userSelections];
      }

      // Restore match the pairs selections if saved
      if (this.test.type === 'Match the Pairs' && this.savedAnswers.userSelections) {
        this.userSelections = [...this.savedAnswers.userSelections];
      }
    },


    // Scoring logic for Free Text answers
    scoreFreeTextAnswer() {
      let score = 0;
      const userText = this.freeTextAnswer.toLowerCase();

      // Loop through the rules and check each condition
      this.test.rules.forEach(rule => {
        const word = rule.word.toLowerCase();
        if (rule.condition === 'contains' && userText.includes(word)) {
          score += rule.points;
        } else if (rule.condition === 'does not contain' && !userText.includes(word)) {
          score += rule.points;
        }
      });

      return score;
    },

    // Filter the right options based on already selected values
    filteredRightOptions(index) {
    const selectedValues = this.userSelections.filter(selection => selection !== null);
    return this.rightOptions.filter(option => !selectedValues.includes(option) || this.userSelections[index] === option);
  },

    // Clear the selected option for the specific index
    clearSelection(index) {
    this.userSelections[index] = null; // Clear the selection
    this.emitAnswer(); // Emit the updated answer
  },

    // Emit the answer and its correctness
    emitAnswer() {
    let isCorrect = true;

    if (this.test.type === 'Multiple Choice') {
      const correctOption = this.test.options.find(option => option.checked);
      isCorrect = this.selectedOption === correctOption.text;
    } else if (this.test.type === 'Free Text') {
      const score = this.scoreFreeTextAnswer();
      isCorrect = score >= this.test.required_points;
    } else if (this.test.type === 'Fill the Gaps') {
      const correctAnswers = this.test.question.match(/\[(.*?)\]/g).map(gap => {
        return gap.replace(/\[|\]/g, '').split('|')[0];
      });
      isCorrect = this.userSelections.every((selection, index) => selection === correctAnswers[index]);
    } else if (this.test.type === 'Match the Pairs') {
      isCorrect = this.test.pairs.every((pair, index) => pair.right === this.userSelections[index]);
    } else if (this.test.type === 'Ordering') {
      isCorrect = this.userOrder?.every((option, index) => option.text === this.test.options?.[index]?.text);
    }

    // Emit the full answer object
    this.$emit('set-answer', {
      type: this.test.type,
      selectedOption: this.selectedOption,
      freeTextAnswer: this.freeTextAnswer,
      userSelections: this.userSelections,
      userOrder: this.userOrder,
      isCorrect,
    });
  },
  },
};
</script>

<style scoped>
.test-slide {
  transition: transform 0.2s ease-in-out;
}

.test-slide:hover {
  transform: translateY(-2px);
}

.match-pairs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Equal columns for left and right */
  gap: 20px;
}

.pairs-left,
.pairs-right {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.pairs-left ul,
.pairs-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pairs-left li,
.pairs-right li {
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pairs-right select {
  width: 100%; /* Ensures the dropdown takes up the full width */
  padding: 10px;
  box-sizing: border-box; /* Ensures padding doesn't overflow the container */
  border: 1px solid #ddd;
  border-radius: 5px;
}

.selected-option {
  display: flex;
  align-items: center;
}
</style>
