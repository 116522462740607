<template>
                        <!-- Credits & Subscriptions -->
                        <div
                        class="relative flex flex-col min-w-0 mb-4 lg:mb-0 mt-4 break-words bg-white border border-gray-200 border-solid dark:bg-gray-800 w-full shadow-lg rounded">
                        <div class="rounded-t mb-0 px-0 border-0">
                            <CardHeader :title="title"></CardHeader>

                            <div class="flex flex-wrap items-center px-4 pb-2">
                                <div class="w-full pt-1">
                                    <ul class="my-1">
                                        <li class="flex px-4 my-3">
                                            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                                    d="M9 8h10M9 12h10M9 16h10M5 8h0m0 4h0m0 4h0" />
                                            </svg>
                                            <div
                                                class="flex-grow flex items-center border-gray-100 text-sm text-gray-600 dark:text-gray-50 ml-1">
                                                <div class="flex-grow flex justify-between items-center">
                                                    <div class="self-center">Subscribed to <strong>217</strong> courses
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="flex px-4 my-4">
                                            <svg class="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 24">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                                    d="m3.5 5.5 7.9 6c.4.3.8.3 1.2 0l7.9-6M4 19h16c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H4a1 1 0 0 0-1 1v12c0 .6.4 1 1 1Z" />
                                            </svg>
                                            <div
                                                class="flex-grow flex items-center border-gray-100 text-sm text-gray-600 dark:text-gray-50 ml-1">
                                                <div class="flex-grow flex justify-between items-center leading-5">
                                                    <div class="self-center"><strong>262</strong> active users remaining
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="flex px-4 my-4">
                                            <svg class="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M7 8v8a5 5 0 1 0 10 0V6.5a3.5 3.5 0 1 0-7 0V15a2 2 0 0 0 4 0V8" />
                                            </svg>
                                            <div
                                                class="flex-grow flex items-center border-gray-100 text-sm text-gray-600 dark:text-gray-50 ml-1">
                                                <div class="flex-grow flex justify-between items-center leading-5">
                                                    <div class="self-center"><a href="#" class="text-blue-700">Review
                                                            credits &amp; subscriptions...</a></div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- ./Credits & Subscriptions -->

</template>
<script>
import CardHeader from '@/partials/CardHeader.vue';

export default {
    components: {
        CardHeader
    },
    props: {
        title: String
    }
};
</script>
