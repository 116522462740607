<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900">
    <TopNav></TopNav>

    <!-- Sidebar -->
    <AddCourseSideMenu 
      @publish-course="publishCourse" 
      :courseTitle="courseData.newCourse.title"  
      @option-selected="handleOptionSelected"
      @unit-selected="handleUnitSelected"
      @view-new-course="viewNewCourse"  
      :cards="courseData.units"
      @unit-order-updated="handleUnitOrderUpdated"
    ></AddCourseSideMenu>
    
    <main class="p-4 md:ml-96 h-auto pt-20">
      <!-- Spinner with message -->
      <div v-if="isLoading" class="fixed inset-0 flex items-center justify-center z-50">
        <!-- Transparent background -->
        <div class="absolute inset-0 bg-gray-700 opacity-60"></div>
        <!-- Spinner and text content -->
        <div class="relative z-10 text-center">
          <svg class="animate-spin h-16 w-16 text-secondary-600 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
          </svg>
          <p class="text-md text-white mt-2 font-semibold drop-shadow-xl">Publishing course and content, please wait...</p>
          <p class="text-md text-white mt-1 font-semibold drop-shadow-xl">This may take a few minutes</p>
        </div>
      </div>

      <!-- Conditionally render NewCourse or selected unit -->
                       <!-- Display Validation Errors -->
                <div v-if="validationErrors.length" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6">
                  <strong class="font-bold">Please fix the following errors:</strong>
                  <ul>
                    <li v-for="error in validationErrors" :key="error">{{ error }}</li>
                  </ul>
                </div>

                <component
        :is="getComponentName(selectedUnit?.type)"
        v-if="selectedUnit"
        :courseData="courseData.newCourse"
        :unitOrder="selectedUnit.unit_order"
        :unitData="selectedUnit.data"  
        @data-updated="updateUnitData"
        class="w-full max-w-7xl mx-auto"
      ></component>
    </main>
  </div>   
</template>

<script>
import axios from 'axios';
import TopNav from '@/components/headers/TopNav.vue';
import AddCourseSideMenu from '@/components/AddCourseSideMenu.vue';
import NewCourse from '@/components/NewCourse.vue';
import ContentUnit from '@/components/AddCourse/ContentUnit.vue';
import VideoUnit from '@/components/AddCourse/VideoUnit.vue';
import AudioUnit from '@/components/AddCourse/AudioUnit.vue';
import DocumentUnit from '@/components/AddCourse/DocumentUnit.vue';
import iFrameUnit from '@/components/AddCourse/iFrameUnit.vue';
import ScormUnit from '@/components/AddCourse/ScormUnit.vue';
import TestUnit from '@/components/AddCourse/TestUnit.vue';
import AssignmentUnit from '@/components/AddCourse/AssignmentUnit.vue';


export default {
  data() {
    return {
      selectedUnit: null, // Track the currently selected unit
      unit_counter: 0, // Initialize the unit counter
      validationErrors: [], // New property to track validation errors
      isLoading: false,
      courseData: {
        newCourse: {
          title: '',
          description: '',
          course_code: '',
          category: '',
          price: null,
          hide_from_catalog: false,
          capacity: null,
          level: '',
          start_date: '',
          start_time: '',
          end_date: '',
          end_time: '',
          completion_rules: '',
          completion_percentage: null, 
          learning_path: '',
          certificate_type: ''
        },
        content: [],
        video: [],
        audio: [],
        document: [],
        iframe: [],
        scorm: [],
        test: [],
        testUnit: [],
        assignment: [],
        units: [
          {
            type: 'NewCourse', // NewCourse type
            unit_order: 0,
            data: this.newCourse
          }
        ]
      },
    };
  },
  created() {
    // Automatically select the NewCourse unit as the default
    this.selectedUnit = this.courseData.units[0];
  },
  components: {
    TopNav,
    AddCourseSideMenu,
    NewCourse,
    ContentUnit,
    VideoUnit,
    AudioUnit,
    DocumentUnit,
    iFrameUnit,
    ScormUnit,
    TestUnit,
    AssignmentUnit
  },
  methods: {
    handleUnitOrderUpdated(updatedCards) {
  // Update the units array with the new order
  this.courseData.units = updatedCards.map((card, index) => {
    card.unit_order = index + 1; // Set the correct unit_order based on the new position
    return card;
  });

  console.log('Updated unit orders:', this.courseData.units);
},
    viewNewCourse() {
      this.selectedUnit = this.courseData.units[0]; // Select the NewCourse unit
    },
    handleUnitSelected(unit) {
  const selected = this.courseData.units.find(u => u.unit_order === unit.unit_order);
  if (selected) {
    this.selectedUnit = selected;
  } else {
    console.error('Unit not found:', unit.unit_order);
  }
},
    updateNewCourseData(data) {
      this.courseData.newCourse = { ...this.courseData.newCourse, ...data };
      if (this.selectedUnit && this.selectedUnit.type === 'NewCourse') {
        this.selectedUnit.data = this.courseData.newCourse;
      }
    },
    handleOptionSelected(selectedOption) {
  this.unit_counter++;

  const newUnit = {
    type: selectedOption.type,
    unit_order: this.unit_counter,
    data: {}
  };

  this.courseData.units.push({
    ...newUnit,
    title: `${selectedOption.type} unit ${this.unit_counter}`,
    iconClass: selectedOption.iconClass,
    iconPath: selectedOption.iconPath,
    iconFill: selectedOption.iconFill
  });

  this.selectedUnit = newUnit;
},
updateUnitData(data) {
  if (this.selectedUnit) {
    const unitOrder = this.selectedUnit.unit_order;
    const unitIndex = this.courseData.units.findIndex(unit => unit.unit_order === unitOrder);

    if (unitIndex !== -1) {
      this.courseData.units[unitIndex].data = { ...this.courseData.units[unitIndex].data, ...data };
      this.selectedUnit.data = this.courseData.units[unitIndex].data;

      if (this.selectedUnit.type === 'NewCourse') {
        this.updateNewCourseData(this.selectedUnit.data);
      }
    } else {
      console.error('Unit not found for update:', unitOrder);
    }
  } else {
    console.error('No selected unit found to update.');
  }
},
    getComponentName(type) {
      switch (type) {
        case 'Content':
          return 'ContentUnit';
        case 'Video':
          return 'VideoUnit';
        case 'Audio':
          return 'AudioUnit';
        case 'Presentation':
          return 'DocumentUnit';
        case 'iFrame':
          return 'iFrameUnit';
        case 'Test':
          return 'TestUnit';
        case 'Assignments':
          return 'AssignmentUnit';
        case 'Scorm':
          return 'ScormUnit';
        case 'NewCourse':
          return 'NewCourse';
        default:
          return null;
      }
    },
    validateFields() {
  this.validationErrors = [];

  // Validate Course Title and Description
  if (!this.courseData.newCourse.title) {
    this.validationErrors.push('Course Title is required');
  }

  if (!this.courseData.newCourse.description) {
    this.validationErrors.push('Course Description is required');
  }

  // Validate units for required fields
  this.courseData.units.forEach((unit) => {
    switch (unit.type) {
      case 'Content':
        if (!unit.data.title) {
          this.validationErrors.push(`Content Unit ${unit.unit_order} title is required`);
        }
        if (!unit.data.content) {
          this.validationErrors.push(`Content Unit ${unit.unit_order} content is required`);
        }
        break;
      case 'Video':
        if (!unit.data.title) {
          this.validationErrors.push(`Video Unit ${unit.unit_order} title is required`);
        }
        if (!unit.data.video_path && !unit.data.youtube_link) {
          this.validationErrors.push(`Video Unit ${unit.unit_order} requires either a video path or a YouTube link`);
        }
        break;
      case 'Audio':
        if (!unit.data.title) {
          this.validationErrors.push(`Audio Unit ${unit.unit_order} title is required`);
        }
        if (!unit.data.audio_path) {
          this.validationErrors.push(`Audio Unit ${unit.unit_order} audio path is required`);
        }
        break;
      case 'Presentation':
        if (!unit.data.title) {
          this.validationErrors.push(`Presentation Unit ${unit.unit_order} title is required`);
        }
        if (!unit.data.doc_path) {
          this.validationErrors.push(`Presentation Unit ${unit.unit_order} document path is required`);
        }
        break;
      case 'iFrame':
        if (!unit.data.title) {
          this.validationErrors.push(`iFrame Unit ${unit.unit_order} title is required`);
        }
        if (!unit.data.url) {
          this.validationErrors.push(`iFrame Unit ${unit.unit_order} URL is required`);
        }
        break;
      case 'Assignments':
        if (!unit.data.title) {
          this.validationErrors.push(`Assignments Unit ${unit.unit_order} title is required`);
        }
        if (!unit.data.content) {
          this.validationErrors.push(`Assignments Unit ${unit.unit_order} content is required`);
        }
        break;
      case 'Scorm':
        if (!unit.data.title) {
          this.validationErrors.push(`Scorm Unit ${unit.unit_order} title is required`);
        }
        if (!unit.data.file) {
          this.validationErrors.push(`Scorm Unit ${unit.unit_order} SCORM path is required`);
        }
        break;
      case 'Test':
        if (!unit.data.questions || unit.data.questions.length === 0) {
          this.validationErrors.push(`Test Unit ${unit.unit_order} must have at least one question`);
        }
        break;
      default:
        break;
    }
  });

  // Return true if no validation errors, false otherwise
  return this.validationErrors.length === 0;
},
async publishCourse() {
  if (!this.validateFields()) {
    return;
  }

  this.isLoading = true; // Set loading state to true

  try {
    const thumbnailUpload = this.courseData.newCourse.thumbnail_file
      ? (async () => {
          console.log("Processing course thumbnail");

          const formData = new FormData();
          formData.append("image", this.courseData.newCourse.thumbnail_file);

          try {
            const response = await axios.post(
              `${process.env.VUE_APP_API_URL}/api/upload-image`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            // Replace the local URL with the URL received from the server
            this.courseData.newCourse.thumbnail_url = response.data.url;
            console.log("Thumbnail uploaded successfully:", response.data.url);

            // Clear the file reference since it's now uploaded
            delete this.courseData.newCourse.thumbnail_file;
          } catch (error) {
            console.error("Error uploading thumbnail:", error);
          }
        })()
      : Promise.resolve();

    // Iterate over each unit in courseData.units and upload any files
    const unitUploads = this.courseData.units.map(async (unit) => {
      if (unit.data.file) {
        console.log(`Processing ${unit.type.toLowerCase()} unit:`, unit); // Log the unit

        const formData = new FormData();
        formData.append(unit.type.toLowerCase(), unit.data.file);

        try {
          // Perform the upload to the API endpoint
          const response = await axios.post(
            `${process.env.VUE_APP_API_URL}/api/upload-${unit.type.toLowerCase()}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (unit.type === "Scorm" && unit.data.file) {
            unit.data.scorm_id = response.data.scorm.id;
          }

          // Replace the local path with the URL received from the server
          unit.data[`${unit.type.toLowerCase()}_path`] = response.data.url;
          console.log(`${unit.type} uploaded successfully:`, response.data.url);
        } catch (error) {
          console.error(`Error uploading ${unit.type.toLowerCase()}:`, error);
        }

        // Clear the file reference since it's now uploaded
        delete unit.data.file;
      } else {
        console.log(`No file found for ${unit.type.toLowerCase()} unit:`, unit.data.title);
      }
    });

    await Promise.all([thumbnailUpload, ...unitUploads]);

    // Now, proceed with the rest of the course publishing process
    const mutation = `
      mutation CreateCourse(
        $title: String!,
        $description: String!,
        $course_code: String,
        $category: String,
        $price: Float,
        $hide_from_catalog: Boolean,
        $capacity: Int,
        $level: String,
        $start_date: String,
        $start_time: String,
        $end_date: String,
        $end_time: String,
        $completion_rules: String,
        $completion_percentage: Int, 
        $learning_path: [Int],
        $thumbnail: String,
        $certificate_type: String,
        $content: [ContentInput!],
        $video: [VideoInput!],
        $audio: [AudioUnitInput!],
        $document: [DocumentUnitInput!],
        $scorm: [ScormUnitInput!],
        $iframe: [iFrameUnitInput!],
        $assignment: [AssignmentUnitInput!],
        $test_units: [TestUnitInput!],
        $tests: [TestInput!]
      ) {
        createCourse(
          title: $title,
          description: $description,
          course_code: $course_code,
          category: $category,
          price: $price,
          hide_from_catalog: $hide_from_catalog,
          capacity: $capacity,
          level: $level,
          start_date: $start_date,
          start_time: $start_time,
          end_date: $end_date,
          end_time: $end_time,
          completion_rules: $completion_rules,
          completion_percentage: $completion_percentage, 
          learning_path: $learning_path,
          thumbnail: $thumbnail,
          certificate_type: $certificate_type,
          content: $content,
          video: $video,
          audio: $audio,
          scorm: $scorm,
          iframe: $iframe,
          assignment: $assignment,
          document: $document,
          test_units: $test_units,
          tests: $tests
        ) {
          id
          title
          description
        }
      }
    `;

    const variables = {
      title: this.courseData.newCourse.title,
      description: this.courseData.newCourse.description,
      course_code: this.courseData.newCourse.course_code,
      category: this.courseData.newCourse.category,
      price: this.courseData.newCourse.price,
      hide_from_catalog: this.courseData.newCourse.hide_from_catalog,
      capacity: this.courseData.newCourse.capacity,
      level: this.courseData.newCourse.level,
      start_date: this.courseData.newCourse.start_date,
      start_time: this.courseData.newCourse.start_time,
      end_date: this.courseData.newCourse.end_date,
      end_time: this.courseData.newCourse.end_time,
      completion_rules: this.courseData.newCourse.completion_rules,
      completion_percentage: this.courseData.newCourse.completion_percentage,
      learning_path: this.courseData.newCourse.learning_path,
      thumbnail: this.courseData.newCourse.thumbnail_url,
      certificate_type: this.courseData.newCourse.certificate_type,

      // Content units
      content: this.courseData.units.filter(unit => unit.type === 'Content').map(unit => ({
        title: unit.data.title,
        description: unit.data.content,
        completionOption: unit.data.completionOption,
        timeLimit: unit.data.timeLimit,
        unit_order: unit.unit_order,
      })),

      // Video units
      video: this.courseData.units.filter(unit => unit.type === 'Video').map(unit => ({
        title: unit.data.title,
        video_path: unit.data.video_path,
        youtube_link: unit.data.youtube_link,
        unit_order: unit.unit_order,
        content: unit.data.content,
        completion_option: unit.data.completion_option,
        time_limit: unit.data.time_limit,
      })),

      // Audio units
      audio: this.courseData.units.filter(unit => unit.type === 'Audio').map(unit => ({
        title: unit.data.title,
        audio_path: unit.data.audio_path,
        unit_order: unit.unit_order,
        content: unit.data.content,
        completion_option: unit.data.completion_option,
        time_limit: unit.data.time_limit,
      })),

      // Document units
      document: this.courseData.units.filter(unit => unit.type === 'Presentation').map(unit => ({
        title: unit.data.title,
        file_path: unit.data.doc_path,
        unit_order: unit.unit_order,
        content: unit.data.content,
        completion_option: unit.data.completion_option,
        time_limit: unit.data.time_limit,
      })),

      // iFrame units
      iframe: this.courseData.units.filter(unit => unit.type === 'iFrame').map(unit => ({
        title: unit.data.title,
        url: unit.data.url,
        unit_order: unit.unit_order,
        content: unit.data.content,
        completion_option: unit.data.completion_option,
        time_limit: unit.data.time_limit,
      })),

      // SCORM units
      scorm: this.courseData.units.filter(unit => unit.type === 'Scorm').map(unit => ({
        title: unit.data.title,
        scorm_path: unit.data.scorm_path,
        scorm_id: unit.data.scorm_id,
        unit_order: unit.unit_order,
      })),

      // Assignment units
      assignment: this.courseData.units.filter(unit => unit.type === 'Assignments').map(unit => ({
        title: unit.data.title,
        content: unit.data.content,
        completion_option: unit.data.completionOption,
        unit_order: unit.unit_order,
      })),

      // Test units (unit_order only)
      test_units: this.courseData.units.filter(unit => unit.type === 'Test').map(unit => ({
        unit_order: unit.unit_order,
      })),

      // Tests (questions)
      tests: this.courseData.units
        .filter(unit => unit.data.questions && unit.data.questions.length > 0)
        .flatMap(unit =>
          unit.data.questions.map(question => ({
            type: question.type,
            question: question.question,
            options: question.options,
            pairs: question.pairs,
            requiredPoints: question.requiredPoints,
            rules: question.rules,
            unit_order: unit.unit_order,
          }))
        ),
    };

    // Make the API call to create the course
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
      query: mutation,
      variables: variables,
    });
    this.$router.push({ name: "Courses" });
    console.log("Course created successfully:", response.data);
  } catch (error) {
    this.isLoading = false; // Reset loading state after completion
    console.error("Error creating course:", error);
  } finally {
    this.isLoading = false; // Reset loading state after completion
  }
}
  }
};
</script>
