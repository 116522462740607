<template>
  <div class="antialiased bg-gray-50 min-h-screen text-gray-800">
    <!-- Top Navigation -->
    <TopNav />

    <!-- Blue Background Area -->
    <div class="bg-light-blue">
      <div class="container mx-auto p-8 pt-20">
        <!-- Back Button -->
        <button  @click="goBack" class="mt-4 ml-4 flex items-center text-primary-700 dark:text-primary-300 focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          <span class="ml-2">Back</span>
        </button>

        <!-- Course Header Section -->
        <section class="p-6 rounded-lg flex flex-col md:flex-row items-start md:items-center pt-0">
          <!-- Text Section -->
          <div class="w-full md:w-3/4 mb-6 md:mb-0 md:mr-8">
            <h1 class="text-3xl font-bold mb-4 text-gray-900">{{ course.title }}</h1>
            <button
              @click="handleCourseStart(course.id)"
              :disabled="userProgress === 100"
              :class="{
                'bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow hover:bg-blue-700 transition-colors duration-200 mb-4': userProgress < 100,
                'bg-gray-400 text-white font-semibold py-2 px-4 rounded-lg shadow cursor-not-allowed mb-4': userProgress === 100
              }"
            >
              {{ userProgress === 0 ? 'Start Course' : userProgress === 100 ? 'Course Completed' : 'Continue Course' }}
            </button>
            <div class="text-md text-gray-700">
              <span class="font-semibold">Category:</span> {{ course.category?.name || 'No Category' }}<br>
              <span class="font-semibold">Course Code:</span> {{ course.course_code || 'No Course Code' }}
            </div>
          </div>

          <!-- Thumbnail and Progress Section -->
          <div class="w-full md:w-1/4">
            <img :src="courseThumbnail" alt="Course Thumbnail" class="w-full object-cover rounded-lg shadow-md mb-4" />
            <ProgressBar :progress="userProgress" />
          </div>
        </section>
      </div>
    </div>

    <!-- Course Completion Banner -->
    <div v-if="userProgress === 100" class="bg-green-500 text-white py-4 px-6 mb-6 text-center rounded-lg">
      <h2 class="text-2xl font-bold">Congratulations! You have completed the course!</h2>
      <p class="text-lg mt-2">Click below to download your certificate.</p>
      <button @click="downloadCertificate"
        class="mt-4 bg-white text-green-500 font-semibold py-2 px-6 rounded-lg shadow hover:bg-gray-200 transition-all duration-300 ease-in-out transform hover:scale-105">
        Download Certificate
        <i class="fas fa-download ml-2"></i>
      </button>
    </div>

    <div class="bg-white">
      <div class="container mx-auto p-8">
        <!-- Course Outline Section -->
        <section class="bg-white p-6 rounded-lg shadow-md">
          <h2 class="text-2xl font-bold mb-4">Outline</h2>

          <p class="text-md mb-4">{{ course.description }}</p>

          <div v-if="course.certificate_type" class="text-lg font-semibold text-green-600 dark:text-green-400 mb-4">
            This course awards a certificate.
          </div>

          <!-- Course Units -->
          <div class="border-t border-gray-300 dark:border-gray-700 pt-4">
            <h3 class="text-xl font-semibold mb-4">Course Units</h3>
            <table class="w-full text-left table-auto">
              <thead>
                <tr>
                  <th class="px-4 py-2">Unit</th>
                  <th class="px-4 py-2">Type</th>
                  <th class="px-4 py-2">Status</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(unitType, unitKey) in course.units" :key="unitKey">
                  <tr v-for="unit in unitType" :key="unit.id" class="hover:bg-gray-100 dark:hover:bg-gray-800">
                    <td class="border px-4 py-2 flex items-center">
                      <i :class="getIconClass(unitKey) + ' text-xl mr-2'"></i>
                      {{ unit.title || 'Test Unit' }}
                    </td>
                    <td class="border px-4 py-2 capitalize">{{ unitKey }}</td>
                    <td class="border px-4 py-2 text-center">
                      <span v-if="unit.is_complete" class="inline-flex items-center text-green-500 font-semibold">
                        <i class="fas fa-check-circle mr-2"></i> Completed
                      </span>
                      <span v-else class="text-red-500 font-semibold">Incomplete</span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/headers/TopNav.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import axios from 'axios';

export default {
  components: {
    TopNav,
    ProgressBar,
  },
  data() {
    return {
      course: {},
      userProgress: 0, // Replace with actual progress data
      previousPage: '', // Stores the previous page
    };
  },
  computed: {
    courseThumbnail() {
      return this.course.thumbnail
        ? process.env.VUE_APP_API_URL + `${this.course.thumbnail}`
        : '/img/default-course-image.jpg';
    },
  },
  methods: {
    async fetchCourse(courseId) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `query {
              course(id: ${courseId}) {
                id
                title
                thumbnail
                price
                category {
                  name
                }
                date
                description
                course_code
                certificate_type
                units {
                  contents {
                    id
                    title
                    unit_order
                    is_complete
                  }
                  videos {
                    id
                    title
                    unit_order
                    is_complete
                  }
                  audios {
                    id
                    title
                    unit_order
                    is_complete
                  }
                  documents {
                    id
                    title
                    unit_order
                    is_complete
                  }
                  iframes {
                    id
                    title
                    unit_order
                    is_complete
                  }
                  scorms {
                    id
                    title
                    unit_order
                    is_complete
                  }
                  tests {
                    id
                    unit_order
                    is_complete
                  }
                  assignments {
                    id
                    title
                    unit_order
                    is_complete
                  }
                }
              }
            }`,
        });

        this.course = response.data.data.course;
        this.userProgress = this.calculateUserProgress();

      } catch (error) {
        console.error('Error fetching course:', error);
      }
    },
    async handleCourseStart(courseID) {
      try {
        // Call the CourseEnrollment mutation to enroll the user
        await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            mutation CourseEnrollment($input: CourseEnrollmentInput!) {
              CourseEnrollment(input: $input) {
                id
                title
              }
            }
          `,
          variables: {
            input: {
              course_id: courseID,
            },
          },
        });

        // After successful enrollment, navigate to the first unit
        this.startCourse(courseID);

      } catch (error) {
        console.error('Error enrolling in course:', error);
      }
    },
    startCourse(courseID) {
      // Navigate to the course page with the first unit when "Start Course" is clicked
      this.$router.push({
        name: 'course',
        params: { course: courseID, id: 1 }, // Navigate to the course page with the first unit
      });
    },
    calculateUserProgress() {
      if (!this.course.units || typeof this.course.units !== 'object') {
        return 0;
      }

      const allUnits = Object.values(this.course.units).flat();
      const completedUnits = allUnits.filter(unit => unit.is_complete).length;
      const totalUnits = allUnits.length;

      return totalUnits ? Math.round((completedUnits / totalUnits) * 100) : 0;
    },
    getIconClass(unitType) {
      const icons = {
        contents: 'fas fa-file-alt',
        videos: 'fas fa-video',
        audios: 'fas fa-music',
        documents: 'fas fa-file-pdf',
        iframes: 'fas fa-globe',
        scorms: 'fas fa-laptop',
        tests: 'fas fa-pencil-alt',
        assignments: 'fas fa-tasks',
      };
      return icons[unitType] || 'fas fa-file';
    },
    downloadCertificate() {
      alert('Coming Soon...');
    },
    goBack() {
      if (this.previousPage === 'myCourses') {
        this.$router.push({ name: 'My Courses' });
      } else {
        this.$router.push({ name: 'Course Catalog' });
      }
    },
  },
  mounted() {
    const courseId = this.$route.params.id;
    this.fetchCourse(courseId);

    // Check the previous route
    const fromRoute = this.$router.currentRoute.value.query.from;
    this.previousPage = fromRoute === 'myCourses' ? 'myCourses' : 'courseCatalog';
  },
};
</script>

<style scoped>
.bg-light-blue {
  background-color: #f0f8ff;
}

.container {
  max-width: 1200px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 8px;
}

table th {
  background-color: #f2f2f2;
}

.table-auto tbody tr:hover {
  background-color: #f9f9f9;
}

.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.bg-green-500 {
  background-color: #38a169;
}

.text-green-500 {
  color: #38a169;
}

.text-red-500 {
  color: #e53e3e;
}

button:hover {
  transform: scale(1.05);
}
</style>
