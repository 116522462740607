<template>
  <div>
    <h2 class="text-2xl font-bold mb-4">{{ unitData.title }}</h2>
    
    <!-- Video Display -->
    <div v-if="unitData.video_path || unitData.youtube_link" class="video-container">
      <!-- Video upload -->
      <video 
        v-if="unitData.video_path" 
        :src="getVideoUrl(unitData.video_path)" 
        controls 
        :controlsList="'nodownload'"
        disablePictureInPicture
        @contextmenu.prevent 
        class="rounded-lg shadow-md"
      ></video>
      
      <!-- YouTube link -->
      <iframe
        v-else-if="unitData.youtube_link"
        :src="getYoutubeEmbedUrl(unitData.youtube_link)"
        class="rounded-lg shadow-md"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>

    <!-- Fallback if no video is available -->
    <div v-else>
      <p class="text-red-600">No video available for this unit.</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    courseId: {
      type: [String, Number],
      required: true,
    },
    unitId: {
      type: [String, Number],
      required: true,
    },
  },
  methods: {
    // Function to get the full video URL
    getVideoUrl(videoPath) {
      // Assuming video_path is relative and stored under 'storage/videos/'
      const baseUrl = process.env.VUE_APP_API_URL; // Replace with your actual API URL
      return `${baseUrl}${videoPath}`;
    },

    // Function to convert YouTube link into embed URL
    getYoutubeEmbedUrl(youtubeLink) {
      const videoId = youtubeLink.split('v=')[1];
      const ampersandPosition = videoId.indexOf('&');
      if (ampersandPosition !== -1) {
        return `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}`;
      }
      return `https://www.youtube.com/embed/${videoId}`;
    },
  },
};
</script>

<style scoped>
.video-container {
  width: 100%;
  max-width: 800px; /* Max width for the video container */
  height: auto;
  aspect-ratio: 16 / 9; /* Maintain a 16:9 aspect ratio */
  margin: 0 auto; /* Center the video container */
}

video,
iframe {
  width: 100%;
  height: 100%;
}
</style>
