<template>
    <!-- User overview -->
    <CardHeader :title="title" font="lg" class="mb-7"></CardHeader>
    <div class="grid grid-cols-3 gap-4 mb-7">
  <div class="col-span-2">
    <div data-popover id="chart-info" role="tooltip"
                        class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">

                        <div data-popper-arrow></div>
                    </div>


                    <!-- Donut Chart -->
                    <div id="donut-chart"></div>
  </div>
  <div class="...">
    
<dl class="max-w-md text-gray-900">
    <div class="flex flex-col pb-3">
        <dt class="text-gray-500 md:text-lg dark:text-gray-400 border-b pb-2 mb-2 font-semibold">Active users (10 in total)</dt>
        <dd class="text-3xl font-semibold">10 active</dd>
    </div>
    <div class="flex flex-col py-3">
        <dt class="text-gray-500 md:text-lg dark:text-gray-400 border-b pb-2 mb-2 font-semibold">CPD time logged</dt>
        <dd class="text-3xl font-semibold">10 Hrs</dd>
    </div>
    <div class="flex flex-col pt-3">
        <dt class="text-gray-500 md:text-lg dark:text-gray-400 border-b pb-2 mb-2 font-semibold">Certificates issued</dt>
        <dd class="text-3xl font-semibold">15</dd>
    </div>
</dl>

  </div>

</div>

</template>
<script>
import CardHeader from '@/partials/CardHeader.vue';
import ApexCharts from 'apexcharts';

// Define the function outside of the component
function handleCheckboxChange(event, chart) {
    const checkbox = event.target;
    if (checkbox.checked) {
        switch (checkbox.value) {
            case 'desktop':
                chart.updateSeries([15.1, 22.5, 4.4, 8.4]);
                break;
            case 'tablet':
                chart.updateSeries([25.1, 26.5, 1.4, 3.4]);
                break;
            case 'mobile':
                chart.updateSeries([45.1, 27.5, 8.4, 2.4]);
                break;
            default:
                chart.updateSeries([55.1, 28.5, 1.4, 5.4]);
        }
    } else {
        chart.updateSeries([35.1, 23.5, 2.4, 5.4]);
    }
}

export default {
    components: {
        CardHeader
    },
    props: {
        title: String
    },
    mounted() {
        const getChartOptions = () => {
            return {
                series: [150, 223, 52, 35],
                colors: ["#1C64F2", "#16BDCA", "#FDBA8C", "#E74694"],
                chart: {
                    height: 420,
                    width: "100%",
                    type: "donut",
                },
                stroke: {
                    colors: ["transparent"],
                    lineCap: "",
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontFamily: "Inter, sans-serif",
                                    offsetY: 20,
                                },
                                total: {
                                    showAlways: true,
                                    show: true,
                                    label: "Active Users",
                                    fontFamily: "Inter, sans-serif",
                                    formatter: function (w) {
                                        const sum = w.globals.seriesTotals.reduce((a, b) => {
                                            return a + b
                                        }, 0)
                                        return sum
                                    },
                                },
                                value: {
                                    show: true,
                                    fontFamily: "Inter, sans-serif",
                                    offsetY: -20,
                                    formatter: function (value) {
                                        return value + "k"
                                    },
                                },
                            },
                            size: "70%",
                        },
                    },
                },
                grid: {
                    padding: {
                        top: -2,
                    },
                },
                labels: ["Completed", "Asigned", "Expired", "Inactive"],
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    position: "bottom",
                    fontFamily: "Inter, sans-serif",
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + "k"
                        },
                    },
                },
                xaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + "k"
                        },
                    },
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                },
            }
        }

        if (document.getElementById("donut-chart") && typeof ApexCharts !== 'undefined') {
            const chart = new ApexCharts(document.getElementById("donut-chart"), getChartOptions());
            chart.render();

            const checkboxes = document.querySelectorAll('#devices input[type="checkbox"]');

            checkboxes.forEach((checkbox) => {
                checkbox.addEventListener('change', (event) => handleCheckboxChange(event, chart));
            });
        }
    }
};
</script>
