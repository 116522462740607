<template>
        <CardHeader :title="title" font="lg" class="col-span-2"></CardHeader>

<div class="relative overflow-x-auto px-3 mt-5">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-base text-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                    Courses Name
                </th>
                <th scope="col" class="px-6 py-3">
                    Users
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="border-b border-gray-200 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                    <a href="#" class="text-blue-700">How to write Python code</a>
                </th>
                <td class="px-6 py-4">
                    26
                </td>
            </tr>
            <tr class="border-b border-gray-200 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                    <a href="#" class="text-blue-700">How to write .NET</a>
                </th>
                <td class="px-6 py-4">
                    52
                </td>
            </tr>
            <tr class="border-b border-gray-200 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                    <a href="#" class="text-blue-700">Become a coding pro</a>
                </th>
                <td class="px-6 py-4">
                    21
                </td>
            </tr>
            <tr class="border-b border-gray-200 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                    <a href="#" class="text-blue-700">Health and Safety at work</a>
                </th>
                <td class="px-6 py-4">
                    66
                </td>
            </tr>
            <tr>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                    <a href="#" class="text-blue-700">Python basics</a>
                </th>
                <td class="px-6 py-4">
                    12
                </td>
            </tr>
        </tbody>
    </table>
    <div class="self-center text-center mt-3 mb-3">
    <a href="#" class="text-blue-700 flex items-center justify-center">
        <span class="inline-block">Manage all courses</span>
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m10 16 4-4-4-4"/>
        </svg>
    </a>
</div>
</div>

</template>
<script>
import CardHeader from '@/partials/CardHeader.vue';

export default {
    components: {
        CardHeader
    },
    props: {
        title: String
    }
};
</script>
