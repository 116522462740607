<template>
  <div class="test-unit">
    
    <!-- Test Title and Description Section -->
    <div v-if="!hasStarted" class="flex flex-col items-center text-center py-8">
      <h2 class="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-4">{{ unitData.title || "Test Title" }}</h2>

      <!-- Placeholder for test image -->
      <img :src="testImage" alt="Test Image" class="w-full max-w-md h-64 object-cover rounded-lg shadow-lg mb-6" />

      <!-- Placeholder for description -->
      <p class="text-lg text-gray-700 dark:text-gray-300 mb-4">{{ testDescription || "Please complete the following questions." }}</p>

      <!-- Question Count -->
      <p class="text-md font-medium text-gray-600 dark:text-gray-400 mb-6">You have {{ questions.length }} questions to complete.</p>

      <!-- Start Test Button -->
      <button @click="startTest" class="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-md text-lg shadow-md transition-all duration-300">
        Start Test
      </button>
    </div> 

    <!-- Test Navigation and Questions Section -->
    <div v-else-if="hasStarted && !testCompleted" class="px-6 py-8">
      <div class="flex justify-between items-center mb-6">
        <button @click="previousQuestion" :disabled="currentQuestionIndex === 0"
          class="bg-gray-200 hover:bg-gray-300 text-gray-800 px-6 py-2 rounded-md shadow-sm transition-all duration-200 disabled:opacity-50">
          Previous
        </button>
        <p class="font-bold text-lg text-gray-900 dark:text-gray-100">{{ currentQuestionIndex + 1 }} / {{ questions.length }}</p>
        <button @click="nextQuestion" 
          :disabled="!canMoveNext || currentQuestionIndex === questions.length - 1"
          class="bg-gray-200 hover:bg-gray-300 text-gray-800 px-6 py-2 rounded-md shadow-sm transition-all duration-200 disabled:opacity-50">
          Next
        </button>
      </div>

      <!-- Display Current Question -->
      <div class="question-container bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-8">
        <h3 v-if="currentQuestion.type !== 'Fill the Gaps'" class="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-6">{{ currentQuestion.question }}</h3>
        <h3 v-else class="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-6">Fill the Gaps</h3>

        <!-- Render question types -->
        <!-- Multiple Choice -->
        <div v-if="currentQuestion.type === 'Multiple Choice'" class="space-y-4">
          <div v-for="(option, index) in currentQuestion.options" :key="index" class="flex items-center space-x-4">
            <input type="radio" :id="'option-' + index" :value="option.text" v-model="userAnswers[currentQuestionIndex]" 
              @change="emitAnswer" class="w-6 h-6 text-blue-600 transition-all duration-200 focus:ring-2 focus:ring-blue-600 rounded-full" />
            <label :for="'option-' + index" class="text-lg text-gray-700 dark:text-gray-300 cursor-pointer">{{ option.text }}</label>
          </div>
        </div>

        <!-- Free Text -->
        <div v-if="currentQuestion.type === 'Free Text'" class="mt-4">
          <textarea v-model="userAnswers[currentQuestionIndex]" @input="emitAnswer" rows="4" class="w-full p-4 border-2 border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-600 dark:bg-gray-700 dark:text-gray-100"></textarea>
        </div>

        <!-- Fill the Gaps -->
        <div v-if="currentQuestion.type === 'Fill the Gaps'" class="flex flex-wrap items-center">
          <span v-for="(part, index) in renderedFillTheGaps(currentQuestion)" :key="index" class="flex items-center space-x-2 mb-4">
            <!-- Static text parts -->
            <span v-if="typeof part === 'string'" class="text-lg text-gray-800">{{ part }}</span>
            
            <!-- Dropdown for each gap -->
            <span v-else>
              <select v-model="userAnswers[currentQuestionIndex][part.index]" @change="emitAnswer" 
              class="p-2 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 text-gray-800 text-lg mx-2">
              <option value="" disabled>Select an option</option>
                <!-- Render shuffled options from allGapOptions -->
                <option v-for="option in allGapOptions()[part.index]" :key="option" :value="option">{{ option }}</option>
              </select>
            </span>
          </span>
        </div>

        <!-- Match the Pairs Section -->
<!-- Match the Pairs Section -->
<div v-if="currentQuestion.type === 'Match the Pairs'" class="match-pairs-section">
  <div class="match-pairs-container">
    <div v-for="(pair, index) in currentQuestion.pairs" :key="index" class="match-pairs-row">
      <!-- Left Column (Pairs Left) -->
      <div class="match-pair-left">
        <span class="pair-text">{{ pair.left }}</span>
      </div>

      <!-- Right Column (Dropdown for matching) -->
      <div class="match-pair-right">
        <div v-if="userAnswers[currentQuestionIndex][index]">
          <span class="selected-option">{{ userAnswers[currentQuestionIndex][index] }}</span>
          <button @click="clearSelection(index)" class="ml-2 text-red-600 hover:text-red-800">&times;</button>
        </div>
        <div v-else>
          <select v-model="userAnswers[currentQuestionIndex][index]" @change="emitAnswer" class="pair-dropdown">
            <option value="" disabled>Select a match</option>
            <option v-for="option in filteredRightOptions(index)" :key="option" :value="option">{{ option }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

        <!-- Ordering Section -->
        <div v-if="currentQuestion.type === 'Ordering'" class="mt-6">
               
          <draggable v-model="userOrder" @end="emitAnswer" class="space-y-4">
            <template #item="{ element }">
              <div class="p-4 bg-gray-100 dark:bg-gray-700 rounded-md shadow">{{ element.text }}</div>
            </template>
          </draggable>
          
        </div>
        <!-- Submit Button -->
        <button @click="submitAnswer" :disabled="!userAnswers[currentQuestionIndex]" 
          class="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 mt-6 rounded-md shadow-md transition-all duration-300 disabled:opacity-50">
          Submit
        </button>
      </div>
    </div>

    <!-- Test Result Section -->
    <div v-else-if="testCompleted" class="text-center py-8">
      <h2 class="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-4">{{ testResultMessage }}</h2>

      <!-- Pie Chart Placeholder -->
      <canvas id="resultChart" class="my-4"></canvas>

      <!-- Retry or Next Button -->
      <div>
        <button v-if="!allCorrect" @click="retryTest" class="bg-red-600 hover:bg-red-700 text-white px-8 py-3 rounded-md shadow-md transition-all duration-300">
          Retry Test
        </button>
        <button v-if="allCorrect" @click="nextTest" class="bg-green-600 hover:bg-green-700 text-white px-8 py-3 rounded-md shadow-md transition-all duration-300">
          Next Unit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import draggable from 'vuedraggable';

export default {
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    courseId: {
      type: [String, Number],
      required: true,
    },
    unitId: {
      type: [String, Number],
      required: true,
    },
    savedResponses: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      testImage: 'https://via.placeholder.com/600x200',
      testDescription: 'This test will help you assess your knowledge.',
      questions: this.unitData.tests || [],
      currentQuestionIndex: 0,
      userAnswers: this.unitData.tests.map(() => null), // Initialize with null values for each test
      hasStarted: false,
      testCompleted: false,
      allCorrect: false,
      canMoveNext: false,
      userOrder: this.unitData.tests.some(test => test.type === 'Ordering') 
      ? this.shuffleArray([...this.unitData.tests.find(test => test.type === 'Ordering').options || []])
      : [],
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    testResultMessage() {
      return this.allCorrect ? 'Congratulations! You got all answers correct!' : 'You missed some questions. Try again.';
    },
  },
  mounted() {
  Chart.register(...registerables);
  
  // Initialize userAnswers properly
  this.initializeUserAnswers();

  // Check if there are actual saved answers, excluding timeSpent
  const hasSavedAnswers = Object.keys(this.savedResponses).some(key => {
    const response = this.savedResponses[key];
    return (response?.selectedOption || response?.freeTextAnswer || response?.userSelections || response?.userOrder);
  });

  // If there are no saved answers, don't start the test
  if (hasSavedAnswers) {
    this.restoreSavedAnswers(); // Restore answers when the test starts
    this.hasStarted = true; // Only mark test as started if actual answers are found
  } else {
    this.hasStarted = false; // Ensure test doesn't start automatically
  }
},
  methods: {
    initializeUserAnswers() {
    this.userAnswers = this.questions.map(q => {
      if (q.type === 'Ordering') {
        return this.userOrder.length ? [...this.userOrder] : []; // Ensure userOrder is properly handled
      } else if (q.type === 'Multiple Choice' || q.type === 'Free Text') {
        return null; // Initialize with null for unanswered questions
      } else if (q.type === 'Match the Pairs' && Array.isArray(q.pairs)) {
        return Array(q.pairs.length).fill(null); // Initialize with null for match the pairs
      } else if (q.type === 'Fill the Gaps' && Array.isArray(q.gaps)) {
        return Array(q.gaps.length).fill(null); // Initialize fill the gaps answers
      }
      return null;
    });
  },
    allGapOptions() {
      const gapRegex = /\[(.*?)\]/g;
      const matches = this.currentQuestion.question.match(gapRegex);

      // For each gap, extract the options, shuffle them, and return the set
      return matches.map(match => {
        const gapOptions = match.replace(/\[|\]/g, '').split('|').map(option => option.trim()); // Extract and trim options

        // If it's a single option like [fox], we mix it with other single-option gaps
        if (gapOptions.length === 1) {
          // Get all the single-option gaps in the question and mix them
          const allSingleOptions = this.currentQuestion.question
            .match(gapRegex) // Find all gaps
            .map(gap => gap.replace(/\[|\]/g, '').split('|')) // Extract their options
            .filter(options => options.length === 1) // Only single-option gaps
            .flat(); // Flatten to a single array of options

          // Return a unique, shuffled list of single options
          return this.shuffleArray(Array.from(new Set(allSingleOptions)));
        }

        // Otherwise, return the shuffled set of options for this specific gap
        return this.shuffleArray(gapOptions);
      });
    },

    renderedFillTheGaps(question) {
      const gapRegex = /\[(.*?)\]/g;
      let lastIndex = 0;
      const parts = [];
      let gapIndex = 0;

      question.question.replace(gapRegex, (match, options, offset) => {
        // Split options for each gap and shuffle them
        const optionList = this.allGapOptions(options);

        // Push the static text before the gap
        parts.push(question.question.slice(lastIndex, offset));

        // Push the options for the gap
        parts.push({ options: this.shuffleArray(optionList), index: gapIndex });

        lastIndex = offset + match.length;
        gapIndex++;
      });

      // Add the remaining part of the question (after the last gap)
      parts.push(question.question.slice(lastIndex));

      // Initialize user answers for this question if not already initialized
      if (!this.userAnswers[this.currentQuestionIndex]) {
        this.userAnswers[this.currentQuestionIndex] = Array(gapIndex).fill(null);
      }

      return parts;
    },

    filteredRightOptions() {
      const selectedValues = this.userAnswers[this.currentQuestionIndex].filter(selection => selection !== null);
      return this.shuffleArray(
        this.currentQuestion.pairs.map(pair => pair.right).filter(option => !selectedValues.includes(option))
      );
    },

    startTest() {
      this.hasStarted = true;

      this.userAnswers = this.questions.map(q => {
        if (q.type === 'Ordering') {
          this.userOrder = this.shuffleArray([...q.options]); // Initialize with shuffled options
          return [...this.userOrder]; // Return the shuffled order for userAnswers
        } else if (q.type === 'Match the Pairs') {
          return Array(q.pairs.length).fill(null);
        } else {
          return null;
        }
      });
    },

    shuffleArray(array) {
      return array.sort(() => Math.random() - 0.5);
    },

    previousQuestion() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
        this.canMoveNext = true;
      }
    },

    nextQuestion() {
      if (this.canMoveNext && this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        this.canMoveNext = !!this.userAnswers[this.currentQuestionIndex];
      }
    },

    submitAnswer() {
      const answer = this.userAnswers[this.currentQuestionIndex];
      if (!answer || (Array.isArray(answer) && answer.some(a => a === null || a === ''))) {
        alert('Please answer the question before moving forward.');
        return;
      }
      this.canMoveNext = true;
      this.emitAnswer(this.userAnswers[this.currentQuestionIndex]); // Emit specific answer on submit
      if (this.currentQuestionIndex === this.questions.length - 1) {
        this.calculateResults();
      } else {
        this.nextQuestion();
      }
    },

    calculateResults() {
      this.testCompleted = true;
      const correctAnswers = this.questions.filter((q, i) => this.checkCorrectness(q, this.userAnswers[i]));
      this.allCorrect = correctAnswers.length === this.questions.length;
      this.drawResultChart();
    },
    scoreFreeTextAnswer(question, userText) {
    let score = 0;

    // Ensure we work with lowercase for case-insensitive comparison
    const userResponse = userText.toLowerCase();

    // Loop through the rules of the question and calculate the score
    question.rules.forEach(rule => {
      const word = rule.word.toLowerCase();

      if (rule.condition === 'contains' && userResponse.includes(word)) {
        score += rule.points;
      } else if (rule.condition === 'does not contain' && !userResponse.includes(word)) {
        score += rule.points;
      }
    });

    return score;
  },
    checkCorrectness(question, answer) {
  let isCorrect = true;
  
  if (question.type === 'Multiple Choice') {
    const correctOption = question.options.find(option => option.checked);
    isCorrect = answer === correctOption?.text;
  } else if (question.type === 'Free Text') {
    const score = this.scoreFreeTextAnswer(question, answer);
      isCorrect = score >= question.required_points; // Check if user achieved required points
  } else if (question.type === 'Fill the Gaps') {
    const correctAnswers = question.question.match(/\[(.*?)\]/g).map(gap => gap.replace(/\[|\]/g, '').split('|')[0]);
    isCorrect = answer.every((selection, index) => selection === correctAnswers[index]);
  } else if (question.type === 'Match the Pairs') {
    isCorrect = question.pairs.every((pair, index) => pair.right === answer[index]);
  } else if (question.type === 'Ordering') {
    // This is where the logic to check the correctness of the ordering goes
    isCorrect = Array.isArray(this.userOrder) && this.userOrder.every((userItem, index) => {
      const correctItem = question.options.find(option => option.text === userItem.text); // Find the correct item
      const expectedPosition = question.options.indexOf(correctItem); // Get its correct position      
      return expectedPosition === index; // Compare user position with the expected position
    });
    
  }

  return isCorrect;
},

    retryTest() {
      this.hasStarted = true;
      this.testCompleted = false;
      this.currentQuestionIndex = 0;
      
      // Reset user answers based on question type
      this.userAnswers = this.questions.map(q => {
        if (q.type === 'Ordering') {
          return this.shuffleArray([...q.options]);
        } else if (q.type === 'Match the Pairs') {
          return Array(q.pairs.length).fill(null);
        } else {
          return null;
        }
      });

      this.canMoveNext = false;

      // Emit reset for all questions
      this.questions.forEach(question => {
        this.$emit('set-answer', question.id, {
          type: question.type,
          selectedOption: null,
          freeTextAnswer: null,
          userSelections: null,
          userOrder: null,
          isCorrect: false
        });
      });
    },

    drawResultChart() {
      this.$nextTick(() => {
        const chartElement = document.getElementById('resultChart');
        if (chartElement) {
          const ctx = chartElement.getContext('2d');
          const totalQuestions = this.questions.length;
          const correctAnswers = this.questions.filter((q, i) => this.checkCorrectness(q, this.userAnswers[i])).length;
          const correctPercentage = (correctAnswers / totalQuestions) * 100;
          const incorrectPercentage = 100 - correctPercentage;
          new Chart(ctx, {
            type: 'pie',
            data: {
              labels: ['Correct', 'Incorrect'],
              datasets: [{
                data: [correctPercentage, incorrectPercentage],
                backgroundColor: [correctPercentage === 100 ? '#4CAF50' : '#FF5252', '#FF5252'],
              }],
            },
          });
        }
      });
    },

    nextTest() {
      this.$emit('next-unit');
    },

    clearSelection(index) {
      this.userAnswers[this.currentQuestionIndex][index] = null;
      this.canMoveNext = false;
    },

    emitAnswer() {
      const question = this.currentQuestion;
      const answer = this.userAnswers[this.currentQuestionIndex];

      // Build the full answer object similar to TestSlide
      const answerObject = {
        type: question.type,
        selectedOption: question.type === 'Multiple Choice' ? answer : null,
        freeTextAnswer: question.type === 'Free Text' ? answer : null,
        userSelections: question.type === 'Fill the Gaps' || question.type === 'Match the Pairs' ? answer : null,
        userOrder: question.type === 'Ordering' ? this.userOrder : null, // Ensure userOrder is emitted
        isCorrect: this.checkCorrectness(question, answer),
      };
      
      // Emit the answerObject and the question ID to match what you're doing in TestSlide
      this.$emit('set-answer', question.id, answerObject);

      console.log('Emitting answer:', {
        testId: question.id,
        answerObject,
      });
    },

    restoreSavedAnswers() {
  // Check if there are saved responses for this unit
  if (this.savedResponses && Object.keys(this.savedResponses).length) {
    this.questions.forEach((question, index) => {
      const savedAnswer = this.savedResponses[question.id];

      if (savedAnswer) {
        if (question.type === "Multiple Choice") {
          this.userAnswers[index] = savedAnswer.selectedOption;
        } else if (question.type === "Free Text") {
          this.userAnswers[index] = savedAnswer.freeTextAnswer;
        } else if (question.type === "Fill the Gaps" || question.type === "Match the Pairs") {
          this.userAnswers[index] = savedAnswer.userSelections;
        } else if (question.type === "Ordering") {
          // Check if saved answer is valid and set the userOrder
          if (Array.isArray(savedAnswer.userOrder) && savedAnswer.userOrder.length > 0) {
            this.userOrder = [...savedAnswer.userOrder]; // Restore the order
            this.userAnswers[index] = [...savedAnswer.userOrder]; // Sync with userAnswers
          } else {
            // Initialize with shuffled options if no valid saved order
            this.userOrder = this.shuffleArray([...question.options]);
            this.userAnswers[index] = [...this.userOrder];
              }
            }
          }
        });
      }
    }
  },
  components: {
    draggable,
  },
};
</script>

<style scoped>
.test-unit {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
}

.question-container {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

canvas {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-middle {
  align-items: center;
}

.match-pairs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.pairs-left ul,
.pairs-right ul {
  list-style: none;
  padding: 0;
}

.pairs-left li,
.pairs-right li {
  padding: 10px;
}
.match-pairs-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add more spacing between rows */
  padding: 20px;
  background-color: #f9f9f9; /* Light background for better visibility */
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.match-pairs-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #e0e0e0; /* Border for a clean structure */
  border-radius: 8px;
  background-color: #ffffff;
}

.match-pair-left {
  flex: 0.45; /* Adjust width to make sure both sides are evenly spaced */
  padding: 12px;
  background-color: #f3f4f6;
  border-radius: 8px;
  text-align: left; /* Ensure text is aligned to the left */
}

.match-pair-right {
  flex: 0.45; /* Make dropdowns match the width of left side */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pair-text {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}

.selected-option {
  background-color: #e0e0e0;
  padding: 8px 12px;
  border-radius: 5px;
  font-weight: 500;
}

.pair-dropdown {
  width: 100%; /* Full width to match the left-side width */
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.pair-dropdown:focus {
  outline: none;
  border-color: #007bff; /* Highlight when dropdown is focused */
}

@media (max-width: 768px) {
  /* Make it responsive */
  .match-pairs-row {
    flex-direction: column; /* Stack rows on smaller screens */
    align-items: flex-start;
  }

  .match-pair-left,
  .match-pair-right {
    flex: 1;
    width: 100%; /* Full width on smaller screens */
  }

  .pair-dropdown {
    margin-top: 10px; /* Add space when stacked */
  }
}
</style>
